<template>
  <div class="container-fluid">
    <div class="px-3">
      <div class="row" v-if="scraping">
        <div class="col">
          <h4>This may take a few minutes.</h4>
          <h4>Please don't close the window.</h4>
        </div>
      </div>
      <div class="row my-4" v-if="scraping">
        <loading class="mx-auto mt-4" :active="scraping" color="red"></loading>
      </div>
      <div v-else>
        <ORTInput
          label="URL"
          :edit="true"
          placeholder="https://example.com"
          v-model="url"
          hint="Crunchbase, Linkedin or company's own URL"
        />
        <div v-show="!scraping" class="my-4 d-flex">
          <ORTButton 
            variant="secondary" 
            class="mr-3"
            width="100%"
            text="Cancel"
            @click="$emit('close')"
          />
          <ORTButton 
            width="100%"
            :text="scrapeText"
            @click="scrape"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { axiosCore } from '@/plugins/axios';
import ORTInput from '@/components/ort-lib/ORTInput.vue';
import ORTButton from '@/ort-lib/components/ORTButton.vue';

export default {
  compatConfig: { MODE: 3 },
  name: 'ScrapeStartup',
  components: {
    ORTInput,
    ORTButton,
  },
  data() {
    return {
      scraping: false,
      url: '', // https://www.linkedin.com/company/motion-society/
    };
  },
  computed: {
    scrapeText() {
      if (this.url) {
        return 'Scrape';
      } else {
        return 'Continue';
      }
    },
  },
  methods: {
    async scrape() {
      try {
        this.scraping = true;
        const result = await axiosCore.post('startups/scrape', { url: this.url });
        this.$emit('next', this.url, result.data);
      } catch (e) {
        this.$emit('next', this.url, {})
        // this.$emit('next', this.url, [
        //   {
        //       "source": "linkedin",
        //       "name": "Motion Society",
        //       "revenue": "232EUR to per year",
        //       "stage": "seed",
        //       "address": "Paris",
        //       "competitors": "Jellysmack, Spore, Pimento, Famebit, Channel, Factory",
        //       "female_founder": true,
        //       "sectors": [
        //           "creator_economy",
        //           "media_and_entertainment",
        //           "social_network",
        //           "video",
        //           "saas"
        //       ],
        //       "tagline": "Motion Society helps content creators reach their full potential and develop their brands across all social media platforms.",
        //       "website": "http://motionsociety.io"
        //   },
        //   {
        //       "source": "website",
        //       "name": "Moso",
        //       "revenue": null,
        //       "stage": null,
        //       "address": "Paris",
        //       "competitors": "Hootsuite, Buffer, Sprout, Social",
        //       "female_founder": false,
        //       "sectors": [
        //           "media_and_entertainment",
        //           "social_network",
        //           "creator_economy"
        //       ],
        //       "tagline": "We help you to boom on the Social Platforms where you don’t get the results you deserve. Our solution turns your artistic DNA into results across all platforms.",
        //       "website": null
        //   },
        // ]);
      } finally {
        this.scraping = false;
      }
    },
  },
};
</script>
