<template>
  <div
    class="warning-message"
    :class="{
      'yellow-theme' : color_theme === 'yellow',
      'green-theme' : color_theme === 'green',
      'red-theme' : color_theme === 'danger'
    }"
  >
    <InfoCircleIcon size="20" :color="iconColor" class="mr-2"/>
    <span class="warning-text text-sm">
      <span class="warning-header text-sm--bold">{{ title }}</span>
      <span>{{ text }}</span>
      <span>
        <a v-if="link" target="blank" :href="link">{{ linkText || link }}</a>
      </span>
    </span>
  </div>
</template>
<script>
import InfoCircleIcon from '@/assets/icons/info-circle.vue';

export default {
  name: 'ORTMessage',
  components: {
    InfoCircleIcon,
  },
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    color_theme: {
      type: String,
    },
    link: {
      type: String,
    },
    linkText: {
      type: String,
    },
  },
  computed: {
    iconColor() {
      return this.color_theme === 'yellow' ? '#e3ab33' : '#489687';
    },
  },
};
</script>
<style lang="scss" scoped>

.warning-message {
  padding: 16px;
  line-height: 24px;
  margin-bottom: 52px;
  display: flex;
  border: 0.5px solid;
  border-radius: 8px;

  .info-circle {
    margin-right: 12px;
    padding: 10px;
  }

  .warning-text {
    display: flex;
    flex-direction: column;

    .warning-header {
      margin-bottom: 4px;
    }
  }
}
.yellow-theme {
  background: $yellow-500;
  color: $red-200;
  border-color: $red-200;
  .warning-header {
    color: $red-200;
  }
  .info-circle {
    background-color: $yellow-darker-color !important;
  }
}
.green-theme {
  background: $green-500;
  color: $green-100;
  border-color: $green-200;
  .warning-header {
    color: $green-100;
  }
  .info-circle {
    background-color: $green-100 !important;
  }
}
.red-theme {
  background: $red-500;
  color: $red-100;
  border-color: $red-200;
  .warning-header {
    color: $red-100;
  }
  .info-circle {
    background-color: $red-100 !important;
  }
}
</style>
