import { axiosCore } from '@/plugins/axios';
import { errorAlert } from '@/ort-lib/utils/utils';

const state = {
  SPVDocuments: [],
  allStartups: [],
  IsAllStartupsTableLoading: false,
  allStartupsCount: 0,
  FundraisingStatusChoices: [],
  StartupSourceChoices: [],
  KYCStatusChoices: [],
  reports: [],
  startup: {},
  startupFundraisings: [],
  startupRelatedUsers: [],
  startupTeam: [],
  startupImage: '',
  kyc_status_options: [],
  kyc_type_options: [],
  incoming_range_options: [],
  sectors: [],
  business_models: [],
  go_to_markets: [],
  countries: [],
  currencies: [],
  startupsDashboard: [],
};

const getters = {
  getSPVDocuments: (state) => state.SPVDocuments,
  getKycStatusOptions: (state) => state.kyc_status_options,
  getKycTypeOptions: (state) => state.kyc_type_options,
  getIncomingRangeOptions: (state) => state.incoming_range_options,
  getSectors: (state) => state.sectors,
  getBusinessModels: (state) => state.business_models,
  getGoToMarkets: (state) => state.go_to_markets,
  getCountries: (state) => state.countries,
  getCurrencies: (state) => state.currencies,
  getAllStartups: (state) => state.allStartups,
  getIsAllStartupsTableLoading: (state) => state.IsAllStartupsTableLoading,
  getAllStartupsCount: (state) => state.allStartupsCount,
  getReports: (state) => state.reports,
  getStartupOld: (state) => state.startup,
  getStartupFundraisings: (state) => state.startupFundraisings,
  getStartupRelatedUsers: (state) => state.startupRelatedUsers,
  getStartupTeam: (state) => state.startupTeam,
  getStartupImage: (state) => state.startupImage,
  getStartupsDashboard: (state) => state.startupsDashboard,
  getStartupSourceChoices: (state) => state.StartupSourceChoices,
};

const mutations = {
  setSPVDocuments: (state, data) => { state.SPVDocuments = data; },
  setStartup: (state, data) => { state.startup = data; },
  setStartupFundraisings: (state, data) => { state.startupFundraisings = data; },
  setStartupRelatedUsers: (state, data) => { state.startupRelatedUsers = data; },
  setStartupImage: (state, data) => { state.startupImage = data; },
  setStartupTeam: (state, data) => { state.startupTeam, data; },
  setAllStartups: (state, data) => {
    state.allStartups = data;
  },
  setKycStatusChoices: (state, data) => { state.kyc_status_options = data; },
  setKycTypeChoices: (state, data) => { state.kyc_type_options = data; },
  setKycIncomingRangeChoices: (state, data) => { state.incoming_range_options = data; },
  setSectorsChoices: (state, data) => { state.sectors = data; },
  setBusinessModelsChoices: (state, data) => { state.business_models = data; },
  setGoToMarketsChoices: (state, data) => { state.go_to_markets = data; },
  setCountriesChoices: (state, data) => { state.countries = data.map((item) => ({ value: item.id, text: item.name })); },
  setCurrencyChoices: (state, data) => { state.currencies = data.map((item) => ({ value: item.id, text: item.name })); },
  setIsAllStartupsTableLoading: (state, payload) => {
    state.IsAllStartupsTableLoading = payload;
  },
  setAllStartupsCount: (state, payload) => {
    state.allStartupsCount = payload;
  },
  setFundraisingStatusChoices: (state, payload) => {
    state.FundraisingStatusChoices = payload;
  },
  setStartupSourceChoices: (state, payload) => {
    state.StartupSourceChoices = payload;
  },
  setKYCStatusChoices: (state, payload) => {
    state.KYCStatusChoices = payload;
  },
  setReports: (state, payload) => {
    state.reports = payload;
  },
  addReport: (state, report) => {
    state.reports = [...state.reports, report];
  },
  deleteReport(state, reportId) {
    state.reports = state.reports.filter((report) => report.id !== reportId);
  },
  updateReport(state, updatedReport) {
    const index = state.reports.findIndex((report) => report.id === updatedReport.id);
    if (index !== -1) {
      state.reports.splice(index, 1, updatedReport);
    }
  },
  setStartupsDashboard: (state, data) => { state.startupsDashboard = data; },
};

const actions = {
  async fetchStartup({ commit }, startupId) {
    try {
      const url = `/startups/${startupId}/`;
      const response = await axiosCore.get(url);
      commit('setStartup', response.data);
      commit('setStartupFundraisings', response.data.fundraisings);
      commit('setStartupRelatedUsers', response.data.related_users);
      commit('setStartupImage', response.data.profile_image);
    } catch (err) {
      throw new Error(err);
    }
  },
  async fetchStartupTeam({ commit }, startupId) {
    try {
      const url = `/startups/${startupId}/team/`;
      const response = await axiosCore.get(url);
      commit('setStartupTeam', response.data);
    } catch (err) {
      throw new Error(err);
    }
  },
  async fetchAllStartups({ commit, dispatch }, {
    perPage, page, searchParam, selectedFilters,
  }) {
    commit('setIsAllStartupsTableLoading', true);
    try {
      const response = await axiosCore.get(`/startups/list-core?${searchParam}page=${page}&page_size=${perPage}${selectedFilters}`);
      commit('setAllStartups', response.data?.results);
      commit('setAllStartupsCount', response.data?.count);
    } catch (error) {
      dispatch('triggerAlert', {
        show: true,
        type: 'danger',
        message: error.message,
      });
    } finally {
      commit('setIsAllStartupsTableLoading', false);
    }
  },
  async exportStartupsTable({ dispatch }, {
    perPage, page, searchParam, selectedFilters,
  }) {
    try {
      const response = await axiosCore.get(`/startups/list-core?${searchParam}page=${page}&page_size=${perPage}${selectedFilters}&format=csv`);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'export.csv');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      dispatch('triggerAlert', {
        show: true,
        type: 'danger',
        message: error.message,
      });
    }
  },
  async getAllReports({ commit, dispatch }, startup_id) {
    try {
      const response = await axiosCore.get(`/startups/${startup_id}/`);
      commit('setReports', response.data?.reporting_startup);
    } catch (err) {
      dispatch('triggerAlert', {
        show: true,
        type: 'danger',
        message: err.message,
      });
    }
  },
  async addNewReport({ commit, dispatch }, body) {
    try {
      const response = await axiosCore.post('/reportings/', body);
      commit('addReport', response.data);
    } catch (err) {
      dispatch('triggerAlert', {
        show: true,
        type: 'danger',
        message: err.message,
      });
    }
  },
  async fetchSPVDocuments({ commit }, query) {
    try {
      const response = await axiosCore.get(`spv_documents/${query}`);
      commit('setSPVDocuments', response.data);
    } catch (err) {
      errorAlert('Could not fetch SPV Documents', err);
    }
  },
  async deleteOneReport({ commit, dispatch }, reportId) {
    try {
      const response = await axiosCore.delete(`/reportings/${reportId}/`);
      commit('deleteReport', reportId);
    } catch (err) {
      dispatch('triggerAlert', {
        show: true,
        type: 'danger',
        message: err.message,
      });
    }
  },
  async editReport({ commit, dispatch }, body) {
    try {
      const response = await axiosCore.patch(`/reportings/${body.id}/`, body);
      commit('updateReport', response.data);
    } catch (err) {
      dispatch('triggerAlert', {
        show: true,
        type: 'danger',
        message: err.message,
      });
    }
  },
  async fetchStartupChoices({ commit }) {
    try {
      const kycStatusChoicesResponse = await axiosCore.get('choices/?choices=kyc_status_choices');
      const KYCTypeChoicesResponse = await axiosCore.get('choices/?choices=KYCTypeChoices');
      const KycIncomingRangeChoices = await axiosCore.get('choices/?choices=kyc_incoming_range_choices');
      const sectorsChoicesResponse = await axiosCore.get('sectors/?type=startup');
      const businessModelsChoicesResponse = await axiosCore.get('businessmodels/');
      const goToMarketsChoices = await axiosCore.get('gotomarkets/');
      const countriesChoices = await axiosCore.get('countries/');
      const currencyChoices = await axiosCore.get('currencies/');
      commit('setKycStatusChoices', kycStatusChoicesResponse.data);
      commit('setKycTypeChoices', KYCTypeChoicesResponse.data);
      commit('setKycIncomingRangeChoices', KycIncomingRangeChoices.data);
      commit('setSectorsChoices', sectorsChoicesResponse.data);
      commit('setBusinessModelsChoices', businessModelsChoicesResponse.data);
      commit('setGoToMarketsChoices', goToMarketsChoices.data);
      commit('setCountriesChoices', countriesChoices.data);
      commit('setCurrencyChoices', currencyChoices.data);
    } catch (err) {
      throw new Error(err);
    }
  },
  async fetchStartupsDashboard({ commit }, filters) {
    try {
      const params = filters || ''
      const response = await axiosCore.get(`/dashboard/startups-created?${params}`);
      commit('setStartupsDashboard', response.data);
    } catch (err) {
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
