<template>
  <div>
    <ORTDropdown
      :key="myKey"
      :options="options"
      placeholder="Create..."
      @input="createEntity"
    />
    <modal
      size="md"
      title="Create new Investor"
      v-if="showCreateInvestorModal"
      @hidden="showCreateInvestorModal = false"
    >
      <CreateInvestor
        @created="investorCreated"
        @close="showCreateInvestorModal = false"
      />
    </modal>
    <modal
      size="md"
      title="Create Investor's User"
      v-if="showCreateUserModal"
      @hidden="showCreateUserModal = false"
    >
      <CreateUser
        :investorToLink="createdInvestor.id"
        @created="userCreated"
        @close="showCreateUserModal = false"
      />
    </modal>
    <modal
      size="md"
      title="Entities Created Successfully"
      v-if="showInviteModal"
      @hidden="showInviteModal = false"
    >
      <NewInvestorOrPartnerConfirmInviteModal
        :entity="createdInvestor"
        :user="createdUser"
        type="investor"
        @close="showInviteModal = false"
      />
    </modal>
    <modal
      size="md"
      title="Create a new investment"
      v-if="showCreateInvestmentModal"
      @hidden="showCreateInvestmentModal = false"
    >
      <CreateInvestment
        @created="investmentCreated"
        @close="showCreateInvestmentModal = false"
      />
    </modal>
    <modal
      title="Create a new Startup"
      v-if="showScrapeStartupModal"
      @hidden="showScrapeStartupModal = false"
    >
      <ScrapeStartup
        @next="startupScraped"
        @close="showScrapeStartupModal = false"
      />
    </modal>
    <modal
      v-if="showCreateStartupModal"
      title="Create a new Startup"
      size="750px"
      @hidden="showCreateStartupModal = false"
    >
      <CreateStartup
        :url="startup.scrapeUrl"
        :scraped="startup.scrapeResult"
        @created="openPipedriveModal"
        @close="showCreateStartupModal = false"
      />
    </modal>
    <modal
      title="Publish to Pipedrive?"
      v-if="showStartupPipedriveModal"
      @hidden="showStartupPipedriveModal = false"
    >
      <PublishStartup
        :startupName="startup.createdStartup.name"
        :startupId="startup.createdStartup.id"
        @close="showStartupPipedriveModal = false"
        @published="publishedStartup"
      />
    </modal>
  </div>
</template>
<script>
import ORTDropdown from '@/ort-lib/components/ORTDropdown.vue';
import CreateUser from '@/components/lib/modals/CreateUser.vue';
import CreateInvestor from '@/components/lib/modals/CreateInvestor.vue';
import ScrapeStartup from '@/components/Pages/Startups/ScrapeStartup.vue';
import CreateStartup from '@/components/Pages/Startups/CreateStartup.vue';
import PublishStartup from '@/components/Pages/Startups/PublishStartup.vue';
import CreateInvestment from '@/components/lib/modals/CreateInvestment.vue';
import NewInvestorOrPartnerConfirmInviteModal from '@/components/Pages/Investors/NewInvestorOrPartnerConfirmInviteModal.vue';

export default {
  name: 'CreateEntityDropdown',
  components: {
    CreateUser,
    ORTDropdown,
    ScrapeStartup,
    CreateStartup,
    PublishStartup,
    CreateInvestor,
    CreateInvestment,
    NewInvestorOrPartnerConfirmInviteModal,
  },
  data() {
    return {
      myKey: 0,
      options: [
        {"text": "Investor", "value": "investor"},
        {"text": "Startup", "value": "startup"},
        {"text": "Investment", "value": "investment"},
      ],
      createdUser: {},
      createdInvestor: {},
      createdInvestment: {},
      startup: {
        scrapeUrl: '',
        scrapeResult: {},
        scrapedStartup: {},
        createdStartup: {},
      },
      showCreateUserModal: false,
      showCreateInvestorModal: false,
      showInviteModal: false,
      showCreateInvestmentModal: false,
      showScrapeStartupModal: false,
      showCreateStartupModal: false,
      showStartupPipedriveModal: false,
    };
  },
  methods: {
    createEntity(entity) {
      this.myKey++;
      if (entity === 'investor') {
        this.showCreateInvestorModal = true;
      } else if (entity === 'startup') {
        this.showScrapeStartupModal = true;
      } else if (entity === 'investment') {
        this.showCreateInvestmentModal = true;
      }
    },
    investorCreated(investor) {
      this.createdInvestor = investor;
      this.showCreateInvestorModal = false;
      this.showCreateUserModal = true;
    },
    userCreated(user) {
      this.createdUser = user;
      this.showCreateUserModal = false;
      this.showInviteModal = true;
    },
    async investmentCreated(investment) {
      this.createdInvestment = investment;
      this.showCreateInvestmentModal = false;
      await this.$router.push({ name: 'Investment', params: { id: investment.id } });
    },
    startupScraped(scrapeUrl, scrapeResult) {
      this.startup.scrapeUrl = scrapeUrl;
      this.startup.scrapeResult = scrapeResult;
      this.showScrapeStartupModal = false;
      this.showCreateStartupModal = true;
    },
    openPipedriveModal(createdStartup) {
      this.startup.createdStartup = createdStartup;
      this.showCreateStartupModal = false;
      this.showStartupPipedriveModal = true;
    },
    async publishedStartup({ organization_id, deal_id }) {
      this.showStartupPipedriveModal = false;
      await this.$router.push({ name: 'Startup', params: { id: this.startup.createdStartup.id } });
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.ort-msg) {
  display: none;
}
:deep(.modal-root) {
  overflow: visible !important;
}
</style>
