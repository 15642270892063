<template>
  <div class="main-wrapper">
    <ul class="breadcrumbs">
      <li>{{ activeSection }}
        <img :src="arrowRight" alt="" width="16" height="24">
      </li>
      <li>{{ activeSubSection }}</li>
    </ul>
    <div class="sub-menu mt-4" v-for="(section, key) in paths">
      <ul v-if="activeSection === key">
        <div v-for="subSection in section">
          <div v-if="subSection.name === 'Log out'">
            <li :class="{ 'active': focusedSection === subSection.name }" @click="this.actions.removeTokens();">
                <div class="item">
                  <img :src="subSection.icon" width="20" height="20">
                  <span>{{ subSection.name }}</span>
                </div>
            </li>
          </div>
          <div v-else-if="!restrictedSubSections.includes(subSection.name)">
            <p v-if="subSection.pSeparator">{{ subSection.pSeparator }}</p>
            <li :class="{ 'active': focusedSection === subSection.name }" class="mb-2" @click="navigate(subSection.name)">
              <router-link :to="{
                name: subSection.pathName || subSection.name,
                params: subSection.param || {}
              }" class="item">
                <div>
                  <img :src="subSection.icon" width="20" height="20">
                  <span>{{ subSection.name }}</span>
                </div>
              </router-link>
            </li>
          </div>
        </div>
      </ul>
    </div>
  </div>
</template>
<script>
/** @typedef {import("src/ort-lib/stores/accounts/jwt.js").Actions} JwtActions */
/** @typedef {import("src/ort-lib/stores/accounts/jwt.js").Getters} JwtGetters */
import { getAction, getGetter } from '@/utils/jsdoc.js';

export default {
  name: 'SubSidebarMenu',
  props: {
    activeSection: {
      type: String,
      default: 'Dashboards',
      variant: ['Dashboards', 'Finance', 'Investors', 'Dealflow', 'Settings']
    }
  },
  data() {
    return {
      arrowRight: new URL('@/ort-lib/assets/icons/chevron-right.svg', import.meta.url).href,
      actions: {
        removeTokens: getAction(/** @type {JwtActions["removeTokens"]} */ ('removeTokens')),
      },
      activeSubSection: '',
      paths: {
        'Dashboards': [
          {
            subpath: '/finance-dashboard',
            name: 'Finance',
            icon: new URL('@/ort-lib/assets/icons/activity.svg', import.meta.url).href,
          },
          {
            subpath: '/dashboard-investors',
            name: 'Investors',
            pathName: 'Dashboard-investors',
            icon: new URL('@/ort-lib/assets/icons/coins-hand.svg', import.meta.url).href,
          },
          {
            subpath: '/dealflow',
            name: 'Dealflow',
            icon: new URL('@/ort-lib/assets/icons/bar-line-chart.svg', import.meta.url).href,
          }
        ],
        'Finance': [
          {
            pSeparator: 'FINANCE',
            subpath: '/bills',
            name: 'Bills',
            icon: new URL('@/ort-lib/assets/icons/coins-01.svg', import.meta.url).href,
          },
          {
            subpath: '/payouts',
            name: 'Payouts',
            icon: new URL('@/ort-lib/assets/icons/coins-hand.svg', import.meta.url).href,
          },
          {
            subpath: '/valuations',
            name: 'Valuations',
            icon: new URL('@/ort-lib/assets/icons/bar-line-chart.svg', import.meta.url).href,
          },
          {
            pSeparator: 'INVESTMENTS',
            subpath: '/investments',
            name: 'Investment list',
            pathName: 'Finance-Investments',
            icon: new URL('@/ort-lib/assets/icons/coins-stacked-01.svg', import.meta.url).href,
          },
          {
            pSeparator: 'PORTFOLIO',
            subpath: '/fundraisings',
            name: 'Club Deals',
            pathName: "Finance-Fundraisings",
            icon: new URL('@/ort-lib/assets/icons/coins-stacked-04.svg', import.meta.url).href,
          },
          {
            subpath: '/fund/731',
            name: 'Rhapsody I',
            pathName: 'Fund',
            param: { id: 731 },
            icon: new URL('@/ort-lib/assets/icons/coins-stacked-03.svg', import.meta.url).href,
          },
          {
            subpath: '/fund/5785',
            name: 'Rhapsody II',
            pathName: 'Fund',
            param: { id: 5785 },
            icon: new URL('@/ort-lib/assets/icons/coins-stacked-03.svg', import.meta.url).href,
          },
          {
            subpath: '/fund/5786',
            name: 'Paragon',
            pathName: 'Fund',
            param: { id: 5786 },
            icon: new URL('@/ort-lib/assets/icons/coins-stacked-03.svg', import.meta.url).href,
          }
        ],
        'Investors': [
          {
            pSeparator: 'USERS',
            subpath: '/users',
            name: 'Users Access',
            pathName: 'Users',
            icon: new URL('@/ort-lib/assets/icons/users-01.svg', import.meta.url).href,
          },
          {
            pSeparator: 'CLIENTS',
            subpath: '/investors',
            name: 'Investors',
            icon: new URL('@/ort-lib/assets/icons/users-02.svg', import.meta.url).href,
          },
          {
            subpath: '/partners',
            name: 'Partners',
            icon: new URL('@/ort-lib/assets/icons/users-03.svg', import.meta.url).href,
          },
          {
            pSeparator: 'INVESTMENTS',
            subpath: '/investments',
            name: 'Investment list',
            pathName: 'Investors-Investments',
            icon: new URL('@/ort-lib/assets/icons/coins-stacked-03.svg', import.meta.url).href,
          },
          {
            subpath: '/teasers',
            name: 'Teasers',
            icon: new URL('@/ort-lib/assets/icons/activity.svg', import.meta.url).href,
          }
        ],
        'Dealflow': [
          {
            pSeparator: 'STARTUPS',
            subpath: '/startups',
            name: 'Startup list',
            pathName: 'Startups',
            icon: new URL('@/ort-lib/assets/icons/users-01.svg', import.meta.url).href,
          },
          {
            pSeparator: 'FUNDRAISINGS',
            subpath: '/fundraisings',
            name: 'Club deals',
            pathName: 'Dealflow-Fundraisings',
            icon: new URL('@/ort-lib/assets/icons/users-01.svg', import.meta.url).href,
          },
          {
            pSeparator: 'BUSINESS ANGELS',
            subpath: '/ba-profiles',
            name: 'Profiles',
            pathName: 'BAProfiles',
            icon: new URL('@/ort-lib/assets/icons/users-01.svg', import.meta.url).href,
          },
          {
            subpath: '/ba-experiences',
            name: 'Experiences',
            pathName: 'BAExperiences',
            icon: new URL('@/ort-lib/assets/icons/users-01.svg', import.meta.url).href,
          }
        ],
        'Settings': [
          {
            subpath: '/settings',
            name: 'Settings',
            pathName: 'ProfileSettings',
            icon: new URL('@/ort-lib/assets/icons/settings-01.svg', import.meta.url).href,
          },
          {
            name: 'Log out',
            icon: new URL('@/ort-lib/assets/icons/log-out-01.svg', import.meta.url).href,
          }
        ]
      }
    }
  },
  computed: {
    focusedSection() {
      const section = this.paths[this.activeSection];
      for (const subSection of section) {
        if (this.$route.path.includes(subSection.subpath)) {
          return subSection.name;
        }
      }
      return '';
    },
    getters() {
      return {
        isOrtFinance: getGetter(/** @type {JwtGetters["isOrtFinance"]} */ ('isOrtFinance')),
        accesstokenPayload: getGetter(/** @type {JwtGetters["accesstokenPayload"]} */ ('accesstokenPayload')),
      };
    },
    restrictedSubSections() {
      return this.getters.isOrtFinance ? [] : ['Bills', 'Payouts', 'Valuations'] 
    }
  },
  methods: {
    navigate(subSection) {
      this.activeSubSection = subSection;
      this.$emit('closeSidebar');
    },
  },
  watch: {
    activeSection() {
      this.activeSubSection = '';
    }
  }
}
</script>
<style lang="scss" scoped>
.main-wrapper {
  height: 100%;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .breadcrumbs {
    background-color: $white;
    color: $grey-200;
    display: flex;
    gap: 8px;

    li {
      font-size: 14px;
      font-weight: 500;
      color: $grey-200;

      &:last-child {
        color: $grey-100;
      }
    }
  }
  .sub-menu {
    p {
      color: $grey-200;
      margin: 0.5rem 0;
      @extend .text-md--semibold;
    }
    ul {
      display: flex;
      flex-direction: column;
      li {
        display: flex;
        border-radius: 6px;
        width: 100%;
        gap: 8px;

        color: $grey-100;

        img {
          margin-right: 8px;
        }

        &:hover {
          background-color: $red-550;
        }
      }
      .active {
        background-color: $red-500;
        color: $grey-100;
      }
      .item {
        text-decoration: none;
        width: 100%;
        height: 100%;
        color: $grey-100;
        padding: 8px 12px;
        @extend .text-md--semibold;
      }
    }
  }
}
</style>