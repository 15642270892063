<template>
  <div class="dropdown-wrapper">
    <div v-if="loading" class="loading-wrapper">
      <loading loading/>
    </div>
    <div v-else-if="!hasResults">
      <div class="loading-wrapper">
        <span>No results found</span>
      </div>
    </div>
    <div v-else>
      <div v-if="!isEmpty(investorResults.results)" class="entry-set">
        <div class="heading">
          <span>INVESTORS</span>
          <span @click="viewAll('Investors')">
            View all ({{ investorResults.count }}) <i class="fas fa-arrow-right"></i>
          </span>
        </div>
        <SearchDropdownItem
          v-for="investor in investorResults.results"
          :key="investor.id"
          :entry="investor"
          entryType="Investor"
          @entry-clicked="entryClicked"
        />
      </div>
      <div v-if="!isEmpty(userResults.results)" class="entry-set">
        <div class="heading">
          <span>USERS</span>
          <span @click="viewAll('Users')">
            View all ({{ userResults.count }}) <i class="fas fa-arrow-right"></i>
          </span>
      </div>
        <SearchDropdownItem
          v-for="user in userResults.results"
          :key="user.id"
          :entry="user"
          entryType="CustomUser"
          @entry-clicked="entryClicked"
        />
      </div>
      <div v-if="!isEmpty(startupResults.results)" class="entry-set">
        <div class="heading">
          <span>STARTUPS</span>
          <span @click="viewAll('Startups')">
            View all ({{ startupResults.count }}) <i class="fas fa-arrow-right"></i>
          </span>
        </div>
        <SearchDropdownItem
          v-for="startup in startupResults.results"
          :key="startup.id"
          :entry="startup"
          entryType="Startup"
          @entry-clicked="entryClicked"
        />
      </div>
      <div v-if="!isEmpty(fundraisingResults.results)" class="entry-set">
        <div class="heading">
          <span>FUNDRAISINGS</span>
          <span @click="viewAll('Fundraisings')">
            View all ({{ fundraisingResults.count }}) <i class="fas fa-arrow-right"></i>
          </span>
        </div>
        <SearchDropdownItem
          v-for="fundraising in fundraisingResults.results"
          :key="fundraising.id"
          :entry="fundraising"
          entryType="Fundraising"
          @entry-clicked="entryClicked"
        />
      </div>
      <div v-if="!isEmpty(investmentResults.results)" class="entry-set">
        <div class="heading">
          <span>INVESTMENTS</span>
          <span @click="viewAll('Investments')">
            View all ({{ investmentResults.count }}) <i class="fas fa-arrow-right"></i>
          </span>
        </div>
        <SearchDropdownItem
          v-for="investment in investmentResults.results"
          :key="investment.id"
          :entry="investment"
          entryType="Investment"
          @entry-clicked="entryClicked"
        />
      </div>
      <div v-if="!isEmpty(partnerResults.results)" class="entry-set">
        <div class="heading">
          <span>PARTNERS</span>
          <span @click="viewAll('Partners')">
            View all ({{ partnerResults.count }}) <i class="fas fa-arrow-right"></i>
          </span>
        </div>
        <SearchDropdownItem
          v-for="partner in partnerResults.results"
          :key="partner.id"
          :entry="partner"
          entryType="Partner"
          @entry-clicked="entryClicked"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ORTDropdown from '@/ort-lib/components/ORTDropdown.vue';
import SearchDropdownItem from '@/components/utils/SearchDropdownItem.vue';

export default {
  name: 'SearchBarDropdown',
  components: {
    ORTDropdown,
    SearchDropdownItem,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    userResults: {
      type: Object,
      required: true,
    },
    investorResults: {
      type: Object,
      required: true,
    },
    startupResults: {
      type: Object,
      required: true,
    },
    partnerResults: {
      type: Object,
      required: true,
    },
    investmentResults: {
      type: Object,
      required: true,
    },
    fundraisingResults: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      
    };
  },
  methods: {
    viewAll(type) {
      this.$emit('view-all', type);
    },
    entryClicked(entry, type) {
      this.$emit('entry-clicked', entry, type);
    },
  },
  computed: {
    hasResults() {
      return (
        !this.isEmpty(this.userResults.results) ||
        !this.isEmpty(this.investorResults.results) ||
        !this.isEmpty(this.startupResults.results) ||
        !this.isEmpty(this.partnerResults.results) ||
        !this.isEmpty(this.investmentResults.results) ||
        !this.isEmpty(this.fundraisingResults.results)
      );
    },
  },
  async mounted() {
    
  },
};
</script>
<style lang="scss" scoped>
.dropdown-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-top: 10px;
  width: 40%;
  max-height: 60vh;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  z-index: 1001;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 90%;
  }
}
.loading-wrapper {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.entry-set {
  border-bottom: 1px solid #e0e0e0;
}
.heading {
  @extend .text-sm--bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;

  span {
    font-size: 14px;
    font-weight: 500;
  }

  span:first-child {
    color: #8e8e93;
  }

  span:nth-child(2) {
    cursor: pointer;
  }
}
</style>
