import { axiosCore } from '@/plugins/axios';
import { getTotal } from '@/utils/formatters.js';


const state = {
  feesAmount: [],
  clubDealsRaisedAmounts: [],
  isClubDealsRaisedAmountLoading: false,
  corporatesRaisedAmounts: [],
  isCorporatesRaisedAmountLoading: false,
  paragonRaisedAmounts: [],
  isParagonRaisedAmountLoading: false,
  rhapsodyRaisedAmounts: [],
  isRhapsodyRaisedAmountLoading: false,
  rhapsody2RaisedAmounts: [],
  isRhapsody2RaisedAmountLoading: false,

  // Valuations
  paragonValuations: [],
  areParagonValuationsLoading: false,
  rhapsody2Valuations: [],
  areRhapsody2ValuationsLoading: false,
  rhapsodyValuations: [],
  areRhapsodyValuationsLoading: false,
  corporateValuations: [],
  areCorporateValuationsLoading: false,
  clubDealValuations: [],
  areClubDealValuationsLoading: false,
};

const getters = {
  getListFeesFromYear: (state) => (year) => state.feesAmount.filter((fees) => fees.year === year)
    .map((fees) => fees.amount),
  getTotalFeesFromYear: (state) => (year) => state.feesAmount.filter((fees) => fees.year === year)
    .map((fees) => fees.amount).reduce((partialSum, a) => partialSum + a, 0),
  getRaisedAmountsAreLoading: (state) => state.isRhapsodyRaisedAmountLoading
    || state.isRhapsody2RaisedAmountLoading || state.isParagonRaisedAmountLoading
    || state.isClubDealsRaisedAmountLoading || state.isCorporatesRaisedAmountLoading,
  getClubDealsRaisedAmounts: (state) => state.clubDealsRaisedAmounts,
  getCorporatesRaisedAmounts: (state) => state.corporatesRaisedAmounts,
  getParagonRaisedAmounts: (state) => state.paragonRaisedAmounts,
  getRhapsodyRaisedAmounts: (state) => state.rhapsodyRaisedAmounts,
  getRhapsody2RaisedAmounts: (state) => state.rhapsody2RaisedAmounts,

  // Valuations
  getParagonValuations: (state) => {
    const data = {
      investment_offer: 'Paragon',
      invested: getTotal(state.paragonValuations, 'invested'),
      current_net_fair_value: getTotal(state.paragonValuations, 'current_net_fair_value'),
      net_multiple: getTotal(state.paragonValuations, 'net_multiple'),
      manual_exited_value: getTotal(state.paragonValuations, 'manual_exited_value'),
    };

    return data;
  },
  getRhapsody2Valuations: (state) => {
    const data = {
      investment_offer: 'Rhapsody II',
      invested: getTotal(state.rhapsody2Valuations, 'invested'),
      current_net_fair_value: getTotal(state.rhapsody2Valuations, 'current_net_fair_value'),
      net_multiple: getTotal(state.rhapsody2Valuations, 'net_multiple'),
      manual_exited_value: getTotal(state.rhapsody2Valuations, 'manual_exited_value'),
    };

    return data;
  },
  getRhapsodyValuations: (state) => {
    const data = {
      investment_offer: 'Rhapsody I',
      invested: getTotal(state.rhapsodyValuations, 'invested'),
      current_net_fair_value: getTotal(state.rhapsodyValuations, 'current_net_fair_value'),
      net_multiple: getTotal(state.rhapsodyValuations, 'net_multiple'),
      manual_exited_value: getTotal(state.rhapsodyValuations, 'manual_exited_value'),
    };

    return data;
  },
  getCorporateValuations: (state) => {
    const data = {
      investment_offer: 'Corporate',
      invested: getTotal(state.corporateValuations, 'invested'),
      current_net_fair_value: getTotal(state.corporateValuations, 'current_net_fair_value'),
      net_multiple: getTotal(state.corporateValuations, 'net_multiple'),
      manual_exited_value: getTotal(state.corporateValuations, 'manual_exited_value'),
    };

    return data;
  },
  getClubDealValuations: (state) => state.clubDealValuations,

  getValuations: (_, getters) => [
    { ...getters.getParagonValuations },
    { ...getters.getRhapsody2Valuations },
    { ...getters.getRhapsodyValuations },
    { ...getters.getCorporateValuations },
    { ...getters.getClubDealValuations },
  ],
};

const actions = {
  async fetchParagonFundRaisedAmount({ commit }, date) {
    const url = `dashboard/amount-raised-fund?fundraising__startup__name=Paragon&date=${date || ''}`;
    try {
      commit('setParagonRaisedAmountLoading', true);
      const response = await axiosCore.get(url);
      commit('setParagonRaisedAmount', response.data);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      commit('setParagonRaisedAmountLoading', false);
    }
  },

  async fetchRhapsodyFundRaisedAmount({ commit }, date) {
    const url = `dashboard/amount-raised-fund?fundraising__startup__name=Rhapsody+I&date=${date || ''}`;
    try {
      commit('setRhapsodyRaisedAmountLoading', true);
      const response = await axiosCore.get(url);
      commit('setRhapsodyRaisedAmount', response.data);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      commit('setRhapsodyRaisedAmountLoading', false);
    }
  },

  async fetchRhapsody2FundRaisedAmount({ commit }, date) {
    const url = `dashboard/amount-raised-fund?fundraising__startup__name=Rhapsody+II&date=${date || ''}`;
    try {
      commit('setRhapsody2RaisedAmountLoading', true);
      const response = await axiosCore.get(url);
      commit('setRhapsody2RaisedAmount', response.data);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      commit('setRhapsody2RaisedAmountLoading', false);
    }
  },

  async fetchClubDealsRaisedAmount({ commit }, date) {
    const url = `dashboard/amount-raised-clubdeal?date=${date || ''}`;
    try {
      commit('setClubDealsRaisedAmountLoading', true);
      const response = await axiosCore.get(url);
      commit('setClubDealsRaisedAmount', response.data);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      commit('setClubDealsRaisedAmountLoading', false);
    }
  },

  async fetchCorporatesRaisedAmount({ commit }) {
    const hardcodedCorporateData = {
      total: 5500000,
      current_year: null,
      previous_year: null,
      current_month: null,
      previous_month: null,
      current_week: null,
      previous_week: null,
    };
    commit('setCorporatesRaisedAmount', hardcodedCorporateData);
    commit('setCorporatesRaisedAmountLoading', false);
  },
};

const mutations = {
  setFeesAmount: (state, feesAmount) => (state.feesAmount = feesAmount),
  setClubDealsRaisedAmount: (state, clubDealsRaisedAmounts) => {
    state.clubDealsRaisedAmounts = clubDealsRaisedAmounts;
  },
  setClubDealsRaisedAmountLoading: (state, isClubDealsRaisedAmountLoading) => {
    state.isClubDealsRaisedAmountLoading = isClubDealsRaisedAmountLoading;
  },
  setCorporatesRaisedAmount: (state, corporatesRaisedAmounts) => {
    state.corporatesRaisedAmounts = corporatesRaisedAmounts;
  },
  setCorporatesRaisedAmountLoading: (state, isCorporatesRaisedAmountLoading) => {
    state.isCorporatesRaisedAmountLoading = isCorporatesRaisedAmountLoading;
  },
  setParagonRaisedAmount: (state, paragonRaisedAmounts) => {
    state.paragonRaisedAmounts = paragonRaisedAmounts;
  },
  setParagonRaisedAmountLoading: (state, isParagonRaisedAmountLoading) => {
    state.isParagonRaisedAmountLoading = isParagonRaisedAmountLoading;
  },
  setRhapsodyRaisedAmount: (state, rhapsodyRaisedAmounts) => {
    state.rhapsodyRaisedAmounts = rhapsodyRaisedAmounts;
  },
  setRhapsodyRaisedAmountLoading: (state, isRhapsodyRaisedAmountLoading) => {
    state.isRhapsodyRaisedAmountLoading = isRhapsodyRaisedAmountLoading;
  },
  setRhapsody2RaisedAmount: (state, rhapsody2RaisedAmounts) => {
    state.rhapsody2RaisedAmounts = rhapsody2RaisedAmounts;
  },
  setRhapsody2RaisedAmountLoading: (state, isRhapsody2RaisedAmountLoading) => {
    state.isRhapsody2RaisedAmountLoading = isRhapsody2RaisedAmountLoading;
  },

  // Valuations
  setParagonValuations: (state, payload) => { state.paragonValuations = payload; },
  setAreParagonValuationsLoading: (state, payload) => { state.areParagonValuationsLoading = payload; },
  setRhapsody2Valuations: (state, payload) => { state.rhapsody2Valuations = payload; },
  setAreRhapsody2ValuationsLoading: (state, payload) => { state.areRhapsody2ValuationsLoading = payload; },
  setRhapsodyValuations: (state, payload) => { state.rhapsodyValuations = payload; },
  setAreRhapsodyValuationsLoading: (state, payload) => { state.areRhapsodyValuationsLoading = payload; },
  setCorporateValuations: (state, payload) => { state.corporateValuations = payload; },
  setAreCorporateValuationsLoading: (state, payload) => { state.areCorporateValuationsLoading = payload; },
  setClubDealValuations: (state, payload) => { state.clubDealValuations = payload; },
  setAreClubDealValuationsLoading: (state, payload) => { state.areClubDealValuationsLoading = payload; },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
