<template>
  <div class="p-3">
    <div v-if="loading" class="d-flex flex-column">
      <h4>Creating User, please wait...</h4>
      <loading class="mx-auto mt-4" :active="loading" color="red"/>
    </div>
    <div v-else>
      <ORTMessage
        class="ort-msg"
        title="Creating a User will not send any email."
        text="To invite this user, you must go to the 'User Management' tab and invite this user."
        linkText="Follow this link for more information on Onboarding."
        link="https://www.notion.so/oneragtime/Onboarding-new-Clients-85ff73eb2805407f9cf1b292eede472e"
      />
      <form @submit.prevent="createContact">
        <div class="rows">
          <div class="row-wrapper">
            <ORTInput
              name="first_name"
              placeholder="First name"
              :modelValue="body.first_name"
              @update:modelValue="updateBody"
              required
              edit
            />
            <ORTInput
              name="last_name"
              placeholder="Last name"
              :modelValue="body.last_name"
              @update:modelValue="updateBody"
              required
              edit
            />
          </div>
          <div class="row-wrapper">
            <ORTInput
              name="email"
              placeholder="Email"
              :modelValue="body.email"
              @update:modelValue="updateBody"
              type="email"
              required
              edit
            />
            <ORTInput
              name="phone_number"
              placeholder="Phone number (optional)"
              :modelValue="body.phone_number"
              @update:modelValue="updateBody"
              edit
            />
          </div>
          <ORTToggle
            title="Preferred Language"
            :options="options"
            :initialValue="body.preferred_language"
            field="preferred_language"
            @click="updateBody"
            required
          />
        </div>
        <div class="d-flex mt-4">
          <ORTButton
            width="100%"
            @click="$emit('close')"
            variant="secondary"
            text="Cancel"
          />
          <ORTButton
            width="100%"
            class="ml-3"
            text="Create user"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
/** @typedef {import("@/ort-lib/stores/entities/partner").Actions} PartnerActions */
/** @typedef {import("src/store/modules/accounts/user_info").Actions} UserInfoActions */
/** @typedef {import("src/store/modules/entities/investors/investor").Actions} InvestorActions */

import { getAction } from '@/utils/jsdoc';
import ORTInput from '@/ort-lib/components/ORTInput.vue';
import ORTButton from '@/ort-lib/components/ORTButton.vue';
import ORTMessage from '@/components/ort-lib/ORTMessage.vue';
import ORTToggle from '@/ort-lib/components/ORTToggle.vue';
import { errorAlert, successAlert } from '@/ort-lib/utils/utils';

export default {
  name: 'CreateUser',
  components: {
    ORTInput,
    ORTButton,
    ORTToggle,
    ORTMessage,
  },
  props: {
    investorToLink: {
      type: Number,
      required: false,
    },
    partnerToLink: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      body: {},
      options: [
        { value: 'FR', text: 'French' },
        { value: 'EN', text: 'English' },
      ],
      requiredValues: {
        first_name: false,
        last_name: false,
        email: false,
      },
      actions: {
        createUser: getAction(/** @type {UserInfoActions["createUser"]} */ ('createUser')),
        addPartnerTeamMember: getAction(/** @type {PartnerActions["addPartnerTeamMember"]} */ ('addPartnerTeamMember')),
        addInvestorTeamMember: getAction(/** @type {InvestorActions["addInvestorTeamMember"]} */ ('addInvestorTeamMember')),
      },
    };
  },
  methods: {
    updateBody(value, field) {
      this.body[field] = value;
    },
    async createContact() {
      this.loading = true;
      try {
        const user = await this.actions.createUser(this.body);
        if (this.investorToLink)
          await this.actions.addInvestorTeamMember({ account_id: user.id, investor_id: this.investorToLink });
        if (this.partnerToLink)
          await this.actions.addPartnerTeamMember({ account_id: user.id, partner_id: this.partnerToLink });
        successAlert('User created successfully');
        this.$emit('created', user);
        this.$emit('close');
      } catch (error) {
        errorAlert('Failed to create User', error);
      }
      this.loading = false;
    },
  },
  mounted() {
    this.body = {};
  },
};
</script>
<style lang="scss" scoped>
.rows {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.row-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
}
</style>
