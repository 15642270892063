// Store index

// Required imports
import { createStore } from 'vuex';
import state from './state.js';
import { mutations } from './mutations.js';
import actions from './actions.js';
import getters from './action_getters.js';

// Modules
import jwt from '@/ort-lib/stores/accounts/jwt.js';
import user from '@/ort-lib/stores/accounts/user.js';
import relationships from '@/ort-lib/stores/relationships/relationships.js';
import selects from './modules/global/selects.js';
import kycOld from './modules/kyc/kyc.js';
import bills from './modules/finances/bills.js';
import payouts from './modules/cashflow/payouts.js';
import modalsFinance from './modules/modalsFinance.js';
import investor from './modules/entities/investors/investor.js';
import investments from './modules/cashflow/investments.js';
import userInfo from './modules/accounts/user_info.js';
import documents from './modules/files/documents.js';
import partner from './modules/entities/partners/partner.js';
import partner_new from '@/ort-lib/stores/entities/partner.js';
import userLogin from './modules/user_management/user_login.js';
import buildDocuments from '@/store/modules/files/buildDocuments.js';
import dashboard from '@/store/modules/dashboard';
import fundraising from './modules/dealflow/fundraising.js';
import choices from './modules/global/choices.js';
import kyc_fund_choices from './modules/kyc/kyc_fund_choices.js';
import kyc_fund from './modules/kyc/kyc_fund.js';
import fundInvestments from './modules/dealflow/fundInvestments.js';
import investors from '@/store/modules/entities/investors/investors.js';
import partners from './modules/entities/partners/partners.js';
import startups from '@/store/modules/startups.js';
import startups_new from '@/ort-lib/stores/entities/startup.js';
import fundraisings from './modules/dealflow/fundraisings.js';
import users from './modules/accounts/users.js';
import kyc from '@/ort-lib/stores/kyc/kyc.js';
import startupEvents from './modules/events/startup.js';
import investmentEvents from './modules/events/investment.js';
import investorEvents from './modules/events/investor.js';
import fundraisingCommunity from './modules/dealflow/fundraisingCommunity.js';
import metrics from '@/components/Pages/Dashboard/DashboardMetrics/metrics.js';
import baProfiles from './modules/dealflow/baProfiles.js';
import baExperiences from './modules/dealflow/baExperiences.js';

export const store = createStore({
  state,
  mutations,
  actions,
  getters,
  modules: {
    jwt,
    selects,
    relationships,
    kycOld,
    kyc,
    bills,
    payouts,
    modalsFinance,
    investor,
    investments,
    user,
    userInfo,
    buildDocuments,
    documents,
    partner,
    partner_new,
    dashboard,
    fundraising,
    choices,
    kyc_fund_choices,
    kyc_fund,
    fundInvestments,
    investors,
    partners,
    startups,
    startups_new,
    fundraisings,
    users,
    userLogin,
    startupEvents,
    investmentEvents,
    investorEvents,
    fundraisingCommunity,
    metrics,
    baProfiles,
    baExperiences,
  },
});
