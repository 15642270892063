/**
 * JSDoc is a markup language used to annotate JavaScript source code files.
 * It is used to add information about the code, eg what it does, what parameters it takes, what it returns.
 * JSDoc is a lighter-weight alternative to TypeScript and does not force you to use types.
 * We are adopting JSDoc in our heavily-used stores to make it easier to use in multiple components.
 * This lets us know what each store method/property takes in and returns.
 *
 * For more info on how we use JSDoc see this: https://www.notion.so/oneragtime/a56830e220b6466dac5d9892610bcb7e
 *
 * The official documentation of JSDoc can be found here: https://jsdoc.app/
 */

/** @typedef {import("src/types/entities/investors.js").InvestorData} InvestorData */
/** @typedef {InvestorData["investor"] Investor} */
/** @typedef {InvestorData["valuations"] Valuations} */
/** @typedef {InvestorData["team_info"] TeamInfo} */
/** @typedef {InvestorData["team_info"][0] InvestorTeamInfo} */
/** @typedef {InvestorData["investments"][0] Investment} */
/** @typedef {import("src/types/entities/investors.js").SearchedInvestor} SearchedInvestor */
/** @typedef {import("src/types/entities/investors.js").SearchedInvestment} SearchedInvestment */
/** @typedef {import("src/types/entities/investors.js").SearchedFundraising} SearchedFundraising */
/** @template T; @typedef {import("src/types/utils.js").PaginatedResults<T>} PaginatedResults<T> */

import { axiosCore } from '@/plugins/axios';
import { isEmpty } from '@/ort-lib/utils/validators';
import { errorAlert, successAlert } from '@/ort-lib/utils/utils';

/**
 * @typedef {{
 *  forceFetchInvestor: boolean;
 *  currentInvestorId: number;
 *  investorData: Object.<number, InvestorData>;
 * }} State
 */

/** @type {State} */
const state = {
  currentInvestorId: 0,
  investorData: {
    0: {
      investor: {},
      valuations: {},
      team_info: {},
      investments: [],
    },
  },
  syncData: null,
  isSyncDataLoading: false,
  exportLoading: false,
};

const getters = {
  /** @param {State} state */
  getCurrentInvestorId: (state) => state.currentInvestorId,
  /** @param {State} state */
  getInvestorData: (state) => state.investorData[state.currentInvestorId],
  /** @param {State} state */
  getInvestor: (state) => state.investorData[state.currentInvestorId].investor,
  /** @param {State} state */
  getInvestorTeam: (state) => state.investorData[state.currentInvestorId].team_info,
  /** @param {State} state */
  getInvestorInvestments: (state) => state.investorData[state.currentInvestorId].investments,
  /** @param {State} state */
  getInvestorOwnerContact: (state) => {
    /** @type {InvestorTeamInfo[]} */
    const team = Object.values(state.investorData[state.currentInvestorId].team_info || {});
    if (!isEmpty(team)) return team.filter((teamMember) => teamMember.is_owner);
    return [];
  },
  /** @param {State} state */
  syncData: (state) => state.syncData,
  /** @param {State} state */
  isSyncDataLoading: (state) => state.isSyncDataLoading,
  /** @param {State} state */
  isExportLoading: (state) => state.exportLoading,
};

const actions = {
  /**
   * Get information about the investor.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} investorId - The ID of the investor.
   */
  async getInvestor({ commit }, investorId) {
    try {
      if (isEmpty(state.investorData[investorId]?.investor) || state.forceFetchInvestor) {
        /** @type {{data: Investor}} */
        const response_wo_corporate = await axiosCore.get(`investors/${investorId}/?fields!=corporate`);
        commit('setInvestorData', { investorId, investorData: { investor: response_wo_corporate.data } });
        commit('setForceFetchInvestor', false);
      }
      commit('setCurrentInvestorId', investorId);
      return state.investorData[investorId].investor;
    } catch (error) {
      errorAlert('The Investor could not be fetched!', error);
    }
  },
  /**
   * Update information about the investor.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{ investorId: number; }} payload
   */
  async updateInvestor({ commit, dispatch }, { investorId, ...data }) {
    try {
      await axiosCore.patch(`/investors/${investorId}/`, data);
      successAlert('The Investor was successfully updated!');
      commit('setForceFetchInvestor', true);
      await dispatch('getInvestor', investorId);
      commit('setCurrentInvestorId', investorId);
      return state.investorData[investorId].investor;
    } catch (error) {
      errorAlert('The Investor could not be updated!', error);
    }
  },
  /**
   * Get team info for the investor.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} investorId - The ID of the investor.
   */
  async getInvestorTeam({ commit }, investorId) {
    try {
      if (isEmpty(state.investorData[investorId]?.team_info) || state.forceFetchInvestor) {
        /** @type {{ data: InvestorTeamInfo[] }} */
        const response = await axiosCore.get(`investors/${investorId}/team-info`);
        commit('setInvestorData', { investorId, investorData: { team_info: response.data } });
        commit('setForceFetchInvestor', false);
      }
      commit('setCurrentInvestorId', investorId);
      return state.investorData[investorId].team_info;
    } catch (error) {
      errorAlert('The Team Info could not be fetched!', error);
    }
  },
  /**
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{ investorId: number; currency: string; exit_value: number; }} payload
   */
  async updateManualExitedValue({ commit }, { investorId, ...data }) {
    try {
      await axiosCore.post(`/investors/${investorId}/exit-value`, data);
      commit('setForceFetchInvestor', true);
      commit('setCurrentInvestorId', investorId);
      successAlert('The exited value was successfully updated!');
    } catch (error) {
      errorAlert('The exited value could not be updated!', error);
    }
  },
  /**
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  investorId: number;
   *  relationshipId: number;
   *  is_owner?: boolean;
   *  is_contact?: boolean;
   *  type?: string;
   *  status?: string;
   * }} payload
   * @returns {Promise<boolean>}
   */
  async updateInvestorTeamMember({ commit, dispatch }, { investorId = 0, relationshipId, ...data }) {
    try {
      await axiosCore.patch(`/relationships/investor/${relationshipId}/`, data);
      successAlert('The team member was successfully updated!');
      commit('setForceFetchInvestor', true);
      await dispatch('getInvestorTeam', investorId);
      commit('setCurrentInvestorId', investorId);
      return state.investorData[investorId].team_info;
    } catch (error) {
      return errorAlert('The team member could not be updated!', error);
    }
  },
  /**
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{ investorId: number; relationshipId: number; }} payload
   * @returns {Promise<boolean>}
   */
  async deleteInvestorTeamMember({ commit, dispatch }, { investorId, relationshipId }) {
    try {
      await axiosCore.delete(`/relationships/investor/${relationshipId}/`);
      commit('setForceFetchInvestor', true);
      await dispatch('getInvestorTeam', investorId);
      commit('setCurrentInvestorId', investorId);
      return successAlert('The team member was successfully deleted!');
    } catch (error) {
      return errorAlert('The team member could not be deleted!', error);
    }
  },
  /**
   *
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{ investor_id: number; account_id: number; }} payload
   */
  async addInvestorTeamMember({ commit, dispatch }, { investor_id, account_id }) {
    const body = {
      account_id, is_contact: true, status: 'validated',
    };
    try {
      /** @type {{ data: InvestorTeamInfo }} */
      const response = await axiosCore.post(`/investors/${investor_id}/relationship/create`, body);
      commit('setForceFetchInvestor', true);
      await dispatch('getInvestorTeam', investor_id);
      commit('setCurrentInvestorId', investor_id);
      successAlert('The team member was successfully added!');
      return response.data;
    } catch (error) {
      errorAlert('The team member could not be added!', error);
    }
  },
  /**
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} investorId - The ID of the investor.
   */
  async getInvestorInvestments({ commit }, investorId) {
    if (isEmpty(state.investorData[investorId]?.investments) || state.forceFetchInvestor) {
      /** @type {{ data: Investment[] }} */
      const response = await axiosCore.get(`/investors/${investorId}/investments`);
      commit('setInvestorData', { investorId, investorData: { investments: response.data } });
      commit('setForceFetchInvestor', false);
    }
    commit('setCurrentInvestorId', investorId);
    return state.investorData[investorId].investments;
  },
  /**
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  searchTerm: number;
   *  page?: number;
   *  page_size?: number;
   * }} params - The search parameters.
  */
  async searchInvestor({ commit }, { searchTerm, page = 1, page_size = 10 }) {
    /** @type {{ data: PaginatedResults<SearchedInvestor> }} */
    const response = await axiosCore.get(`/investors/list-core?search=${searchTerm}&page=${page}&page_size=${page_size}`);
    return response.data;
  },
  /**
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  searchTerm: number;
   *  page?: number;
   *  page_size?: number;
   * }} params - The search parameters.
  */
  async searchInvestment({ commit }, { searchTerm, page = 1, page_size = 10 }) {
    /** @type {{ data: PaginatedResults<SearchedInvestment> }} */
    const response = await axiosCore.get(`/investment/list-core?search=${searchTerm}&page=${page}&page_size=${page_size}`);
    return response.data;
  },
  /**
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  searchTerm: number;
   *  page?: number;
   *  page_size?: number;
   * }} params - The search parameters.
  */
  async searchFundraising({ commit }, { searchTerm, page = 1, page_size = 10 }) {
    /** @type {{ data: PaginatedResults<SearchedFundraising> }} */
    const response = await axiosCore.get(`/fundraisings/list-core?search=${searchTerm}&page=${page}&page_size=${page_size}`);
    return response.data;
  },
};

const mutations = {
  /**
   * The investor id to associate with the getters.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {number} investorId - ID of the investor.
   */
  setCurrentInvestorId: (state, investorId) => state.currentInvestorId = +investorId,
  /**
   * Updates the investor data using supplied fields for the given investor ID.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {number} investorId - ID of the investor.
   * @param {Investor} investorData - Data to set for the investor.
   */
  setInvestorData: (state, { investorId, investorData }) => {
    state.investorData[investorId] = { ...state.investorData[investorId], ...investorData };
  },
  /**
   * Flag which defines if cached data should be refetched or not.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {boolean} fetch - Whether to force fetch or not.
  */
  setForceFetchInvestor: (state, fetch) => {
    state.forceFetchInvestor = fetch;
  },
  syncInvestorData: (state, syncData) => { state.syncData = syncData; },
  setIsSyncDataLoading: (state, isLoading) => { state.isSyncDataLoading = isLoading; },
  setExportLoading: (state, isLoading) => { state.exportLoading = isLoading; },
};

/** @typedef {typeof getters} Getters */
/** @typedef {typeof actions} Actions */
/** @typedef {typeof mutations} Mutations */

/**
 * @module investor
 * @typedef {Object} InvestorStore
 * @property {State} state
 * @property {Getters} getters
 * @property {Actions} actions
 * @property {Mutations} mutations
 */

export default {
  state,
  getters,
  actions,
  mutations,
};
