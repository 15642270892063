<template>
  <div class="wrapper-modal">
    <div class="confirm-section">
      <p>{{capitalize(type)}}:</p>
      <span>
        <a :href="`/${type}s/${entity.id}`" target="blank">
          {{ entity.name }} ({{ humanize(entity.type) }})
          <i class="fas fa-chevron-right ml-1"></i>
        </a>
      </span>
    </div>
    <div class="confirm-section">
      <p>User:</p>
      <span>
        <a :href="`/users/${user.id}`" target="blank">
          {{ `${user.first_name} ${user.last_name}` }} ({{ user.email }})
          <i class="fas fa-chevron-right ml-1"></i>
        </a>
      </span>
    </div>
    <div class="confirm-section">
      <p>Pipedrive Org:</p>
      <span>
        <a :href="pipedriveOrgUrl" target="blank">
          View <strong>{{ entity.name }}</strong> in Pipedrive
          <i class="fas fa-chevron-right ml-1"></i>
        </a>
      </span>
    </div>
    <div class="confirm-section">
      <p>Pipedrive Person:</p>
      <span>
        <a :href="pipedrivePersonUrl" target="blank">
          View <strong>{{ user.first_name }} {{ user.last_name }}</strong> in Pipedrive
          <i class="fas fa-chevron-right ml-1"></i>
        </a>
      </span>
    </div>
    <div class="confirm-section">
      <p>Brevo Person:</p>
      <span>
        <a :href="brevoUrl" target="blank">
          View <strong>{{ user.email }}</strong> in Brevo
          <i class="fas fa-chevron-right ml-1"></i>
        </a>
      </span>
    </div>
    <div>
      <ORTButton
        :text="btnText"
        :disabled="emailSent"
        width="100%"
        :loading="loading"
        @click="sendInviteEmail"
      />
    </div>
  </div>
</template>

<script>
/** @template T; @typedef {import("vue").PropType<T>} PropType<T> */
/** @typedef {import("@/types/accounts/users.js").CreatedUser} CreatedUser */
/** @typedef {import("src/store/modules/accounts/user_info").Actions} UserInfoActions */

import { getAction } from '@/utils/jsdoc';
import ORTButton from '@/ort-lib/components/ORTButton.vue';

export default {
  name: 'NewInvestorOrPartnerConfirmInviteModal',
  components: {
    ORTButton,
  },
  props: {
    entity: {
      type: Object,
      required: true,
    },
    user: {
      /** @type {PropType<CreatedUser>} */
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      emailSent: false,
      actions: {
        sendUserInviteEmail: getAction(/** @type {UserInfoActions["sendUserInviteEmail"]} */ ('sendUserInviteEmail')),
      },
    };
  },
  methods: {
    async sendInviteEmail() {
      this.loading = true;
      await this.actions.sendUserInviteEmail(this.user.id);
      this.emailSent = true;
      this.loading = false;
    },
  },
  computed: {
    btnText() {
      return this.emailSent ? 'Email sent!' : 'Send invite email';
    },
    pipedrivePersonUrl() {
      return `https://oneragtimeinvestors.pipedrive.com/person/${this.user.pipedrive_id}`;
    },
    pipedriveOrgUrl() {
      return `https://oneragtimeinvestors.pipedrive.com/organization/${this.entity.pipedrive_id}`;
    },
    brevoUrl() {
      return `https://app.brevo.com/contact/index/${this.user.sendinblue_contact_id}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-modal {
  padding: 16px 28px;
  display: flex;
  flex-direction: column;
}
.confirm-section {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  margin-bottom: 16px;
  white-space: nowrap;
  p {
    @extend .text-md--bold;
    margin-right: 16px;
    flex-grow: 1;
  }
}
</style>
