const clickOutside = {
  beforeMount(el, binding, vnode) {
    el.event = function (event) {
      // here I check that click was outside the el and his childrens
      if (!(el == event.target || el.contains(event.target))) {
        // Check if the click was on the exception element
        if (binding.arg && binding.arg.contains(event.target)) {
          return;
        }
        // and if it did, call method provided in attribute value
        if (typeof binding.value === 'function') {
          binding.value(event);
        }
      }
    };
    document.body.addEventListener('click', el.event);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.event);
  },
};

export default clickOutside;
