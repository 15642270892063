export function urlIsValid(url) {
  return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?([^" ]+\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?)$/.test(url);
}

export function emailIsValid(email) {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(email);
}
export function isPhoneNumValid(phone) {
  return /^(\+\d{11}|\d{13})$/.test(phone);
}
export function isNumberValid(number) {
  return Number(number) == number;
}
export function multipleEmailsValid(emails) {
  const emailsArray = emails.split(',').map((email) => email.trim());
  return emailsArray.reduce((boolean, email) => emailIsValid(email), true);
}
export function allFieldsValid(objectOfFields) {
  const values = Object.values(objectOfFields);
  return !values.some((value) => !value);
}
export function inputsRequiredValues(required, object, field, validValue) {
  if (required) {
    object[field] = validValue;
  }
}
