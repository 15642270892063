<template>
  <div class="container-fluid">
    <p class="text-lg--regular">
      {{ startupName || 'Startup' }} has been created in the Core!
      <br>
      Should we publish it to Pipedrive now?
    </p>
    <div class="d-flex my-3">
      <ORTButton 
        variant="secondary" 
        class="mr-3"
        width="100%"
        text="Cancel"
        @click="$emit('close')"
      />
      <ORTButton
        width="100%"
        text="Publish"
        @click="publish"
        :loading="loading"
      />
    </div>
  </div>
</template>

<script>
import ORTButton from '@/ort-lib/components/ORTButton.vue';
import { axiosCore } from '@/plugins/axios';
import { successAlert, errorAlert } from '@/ort-lib/utils/utils';

export default {
  compatConfig: { MODE: 3 },
  name: 'PublishStartup',
  components: {
    ORTButton,
  },
  props: {
    startupName: {
      type: String,
      default: '',
    },
    startupId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async publish() {
      try {
        this.loading = true;
        const response = await axiosCore.post(`/startups/${this.startupId}/send-to-pipedrive`, {});
        successAlert("Startup published to Pipedrive correctly!")
        this.loading = false;
        this.$emit("published", response.data);
        this.$emit("close")
      } catch (e) {
        errorAlert("Error while publishing to Pipedrive.", e)
      }
    },
  },
};
</script>
