/**
 * JSDoc is a markup language used to annotate JavaScript source code files.
 * It is used to add information about the code, eg what it does, what parameters it takes, what it returns.
 * JSDoc is a lighter-weight alternative to TypeScript and does not force you to use types.
 * We are adopting JSDoc in our heavily-used stores to make it easier to use in multiple components.
 * This lets us know what each store method/property takes in and returns.
 *
 * 
 * For more info on how we use JSDoc see this: https://www.notion.so/oneragtime/a56830e220b6466dac5d9892610bcb7e
 *
 * The official documentation of JSDoc can be found here: https://jsdoc.app/
 */

/** @typedef {import("src/ort-lib/types/entities/partner.js").Partner} Partner */
/** @typedef {import("src/ort-lib/types/entities/partner.js").PartnerData} PartnerData */
/** @typedef {import("src/ort-lib/types/entities/partner.js").PartnerTeamInfo} PartnerTeamInfo */
/** @typedef {import("src/ort-lib/types/entities/partner.js").SearchedPartner} SearchedPartner */
/** @template T; @typedef {import("src/types/utils.js").PaginatedResults<T>} PaginatedResults<T> */

import { axiosCore } from '@/plugins/axios';
import { isEmpty } from '@/ort-lib/utils/validators';
import { errorAlert, successAlert } from '@/ort-lib/utils/utils';

/**
 * @typedef {{
 *  forceFetchPartner: boolean;
 *  currentPartnerId: number;
 *  partnerData: Object.<number, PartnerData>;
 * }} State
 */

/** @type {State} */
const state = {
  forceFetchPartner: false,
  currentPartnerId: 0,
  partnerData: {
    0: {
      partner: {},
      team_info: [],
    },
  },
};

const getters = {
  /** @param {State} state */
  getCurrentPartnerId: (state) => state.currentPartnerId,
  /** @param {State} state */
  getPartnerData: (state) => state.partnerData[state.currentPartnerId],
  /** @param {State} state */
  getPartner: (state) => state.partnerData[state.currentPartnerId].partner,
  /** @param {State} state */
  partnerTeam: (state) => state.partnerData[state.currentPartnerId].team_info,
};


const actions = {
  /**
   * Get information about the partner.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} partnerId - The ID of the partner.
  */
  async getPartner({ commit }, partnerId) {
    try {
      if (isEmpty(state.partnerData[partnerId]?.partner) || state.forceFetchPartner) {
        /** @type {{data: Partner}} */
        const response = await axiosCore.get(`partners/${partnerId}/`);
        commit('setPartnerData', { partnerId, partnerData: { partner: response.data } });
        commit('setForceFetchPartner', false);
      }
      commit('setCurrentPartnerId', partnerId);
      return state.partnerData[partnerId].partner;
    } catch (error) {
      errorAlert('The Partner could not be fetched!', error);
    };
  },
  /**
   * Update information about the partner.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  partnerId: number;
   *  other_fields: any;
   * }} payload - The partner's data.
  */
  async updatePartner({ commit, dispatch }, { partnerId, ...data }) {
    try {
      await axiosCore.patch(`/partners/${partnerId}/`, data);
      successAlert('The Partner was successfully updated!');
      commit('setForceFetchPartner', true);
      await dispatch('getPartner', partnerId);
      commit('setCurrentPartnerId', partnerId);
      return state.partnerData[partnerId].partner;
    } catch (error) {
      errorAlert('The Partner could not be updated!', error);
    };
  },
  /**
   * Delete Partner.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} partnerId - The ID of the partner.
   * @returns {Promise<boolean>}
  */
  async deletePartner({ commit }, partnerId) {
    try {
      await axiosCore.delete(`/partners/${partnerId}/delete`);
      return successAlert('The Partner was successfully deleted!');
    } catch (error) {
      return errorAlert('The Partner could not be deleted!', error);
    };
  },
  /**
   * Get the partner team.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} partnerId - The ID of the partner.
  */
  async getPartnerTeam({ commit }, partnerId) {
    try {
      if (isEmpty(state.partnerData[partnerId]?.team_info) || state.forceFetchPartner) {
        /** @type {{data: PartnerTeamInfo[]}} */
        const response = await axiosCore.get(`partners/${partnerId}/team-info`);
        commit('setPartnerData', { partnerId, partnerData: { team_info: response.data } });
        commit('setForceFetchPartner', false);
      }
      commit('setCurrentPartnerId', partnerId);
      return state.partnerData[partnerId].team_info;
    } catch (error) {
      errorAlert('The Partner Team could not be fetched!', error);
    };
  },
  /**
   * Update UserPartnerRelationship for the partner.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  partnerId: number;
   *  relationshipId: number;
   *  position?: string;
   *  is_owner?: boolean;
   *  is_contact?: boolean;
   *  is_founder?: boolean;
   *  status?: string;
   * }} payload
  */
  async updatePartnerTeamMember({ commit, dispatch }, { partnerId, relationshipId, ...payload }) {
    try {
      await axiosCore.patch(`/relationships/partner/${relationshipId}/`, payload);
      successAlert('Member updated successfully!');
      commit('setForceFetchPartner', true);
      await dispatch('getPartnerTeam', partnerId);
      commit('setCurrentPartnerId', partnerId);
      return state.partnerData[partnerId].team_info;
    } catch (error) {
      errorAlert('Failed to update member!', error);
    };
  },
  /**
   * Delete UserPartnerRelationship for the partner.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  partnerId: number;
   *  relationshipId: number;
   * }} payload
   * @returns {Promise<boolean>}
  */
  async deletePartnerTeamMember({ commit, dispatch }, { partnerId, relationshipId }) {
    try {
      await axiosCore.delete(`/relationships/partner/${relationshipId}/`);
      commit('setForceFetchPartner', true);
      await dispatch('getPartnerTeam', partnerId);
      commit('setCurrentPartnerId', partnerId);
      return successAlert('Member deleted successfully!');
    } catch (error) {
      return errorAlert('Failed to delete member!', error);
    };
  },
  /**
   *
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{ partner_id: number; account_id: number; }} payload
  */
  async addPartnerTeamMember({ commit, dispatch }, { partner_id, account_id }) {
    const body = {
      account_id, is_contact: true, status: 'validated',
    };
    try {
      /** @type {{ data: PartnerTeamInfo }} */
      const response = await axiosCore.post(`/partners/${partner_id}/relationship/create`, body);
      commit('setForceFetchPartner', true);
      await dispatch('getPartnerTeam', partner_id);
      commit('setCurrentPartnerId', partner_id);
      successAlert('The team member was successfully added!');
      return response.data;
    } catch (error) {
      errorAlert('The team member could not be added!', error);
    }
  },
  /**
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  searchTerm: number;
   *  page?: number;
   *  page_size?: number;
   * }} params - The search parameters.
  */
  async searchPartner({ commit }, { searchTerm, page = 1, page_size = 10 }) {
    /** @type {{ data: PaginatedResults<SearchedPartner> }} */
    const response = await axiosCore.get(`/partners/list-core?search=${searchTerm}&page=${page}&page_size=${page_size}`);
    return response.data;
  },
}

const mutations = {
  /**
   * The partner id to associate with the getters.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {number} partnerId - ID of the partner.
   */
  setCurrentPartnerId: (state, partnerId) => state.currentPartnerId = +partnerId,
  /**
   * Updates the partner data using supplied fields for the given partner ID.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {number} partnerId - ID of the partner.
   * @param {Partner} partnerData - Data to set for the partner.
  */
  setPartnerData: (state, { partnerId, partnerData }) => {
    state.partnerData[partnerId] = { ...state.partnerData[partnerId], ...partnerData };
  },
  /**
   * Flag which defines if cached data should be refetched or not.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {boolean} fetch - Whether to force fetch or not.
  */
  setForceFetchPartner: (state, fetch) => {
    state.forceFetchPartner = fetch;
  },
};

/** @typedef {typeof getters} Getters */
/** @typedef {typeof actions} Actions */
/** @typedef {typeof mutations} Mutations */

/**
 * @module partner
 * @typedef {Object} PartnerStore
 * @property {State} state
 * @property {Getters} getters
 * @property {Actions} actions
 * @property {Mutations} mutations
 */

export default {
  state,
  getters,
  actions,
  mutations,
};
