<template>
  <div :style="cssStyles" class="ort-input">
    <div v-if="label" :class="'label text-' + labelSize + labelStyle">
      <span v-if="requiredField" class="text required-field-asterisc">* </span>
      <span>{{ label }}</span>
    </div>
    <div v-if="!edit" class="parent-input-area-button">
      <div v-if="!edit && !modelValue" class="text-md no-info">no info</div>
      <div v-else-if="!modelValue" class="text-md no-info">Needs to be filled</div>
      <div v-else class="value-no-edit">{{ modelValue }} <span v-if="type == `money`">{{ currency }}</span></div>
    </div>
    <div v-if="edit" class="parent-input-area-button">
      <div :class="inputArea">
        <input
          :type="type"
          :value="modelValue"
          @input="onInput($event.target.value)"
          :placeholder="placeholder"
          :disabled="disabled"
          @blur="onExitFocus($event.target.value)"
          class="input-ort"
        />
        <img v-if="invalidInput" :src="AlertCircleIcon" alt="AlertCircleIcon">
      </div>
    </div>
    <div class="bottom-label">
      <span v-if="!invalidInput" class="hint">{{ hint }}</span>
      <span v-if="messageToShow && unfocused" class="danger">{{ messageToShow }}</span>
    </div>
  </div>
</template>

<script>
import {
  urlIsValid, emailIsValid, isPhoneNumValid, isNumberValid, multipleEmailsValid,
} from '@/utils/customValidators.js';

export default {
  compatConfig: { MODE: 3 },
  name: 'ORTInput',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: [String, Number, Boolean],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      required: false,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
    },
    hint: {
      type: String,
    },
    width: {
      type: String,
      default: '100%',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    parent: {
      type: String,
      required: false,
    },
    currency: {
      type: String,
      required: false,
    },
    // for custom validation:
    requiredField: {
      default: false,
      type: Boolean,
    },
    field: {
      type: String,
      required: false,
    },
    labelSize: {
      type: String,
      default: 'sm--',
    },
    labelStyle: {
      type: String,
      default: 'medium',
    },
  },
  data() {
    return {
      AlertCircleIcon: new URL('@/assets/icons/alert-circle.svg', import.meta.url).href,
      unfocused: false,
      inputIsInvalid: false,
      validValue: false,
      invalidMsg: '',
    };
  },
  computed: {
    invalidInput() {
      return this.inputIsInvalid;
    },
    messageToShow() {
      return this.invalidMsg;
    },
    inputArea() {
      return [
        'input-area', {
          'input-area-invalid': this.invalidInput,
          'input-area-disabled': this.disabled,
        }];
    },
    rightIconName() {
      return this.invalidInput ? 'alert-circle' : 'help-circle';
    },
    rightIconColor() {
      return this.invalidInput ? 'danger' : 'grey';
    },
    rightIconShow() {
      return this.invalidInput || this.hint;
    },
    cssStyles() {
      return {
        '--width': this.width,
      };
    },
  },
  methods: {
    urlIsValid,
    emailIsValid,
    isPhoneNumValid,
    isNumberValid,
    multipleEmailsValid,
    validateInput(value, type) {
      if (type == 'email') {
        this.inputIsInvalid = !this.emailIsValid(value);
        this.invalidMsg = this.inputIsInvalid ? 'The email is not correct' : '';
      } else if (type == 'url') {
        this.inputIsInvalid = !this.urlIsValid(value);
        this.invalidMsg = this.inputIsInvalid ? 'The link is not correct. Please enter a valid url, e.g: example.com / www.example.com' : '';
      } else if (type == 'phone') {
        this.inputIsInvalid = !this.isPhoneNumValid(value);
        this.invalidMsg = this.inputIsInvalid ? 'The phone number is not correct' : '';
      } else if (type == 'number') {
        this.inputIsInvalid = !this.isNumberValid(value);
        this.invalidMsg = this.inputIsInvalid ? 'The number is not valid' : '';
      } else if (type == 'multiple_emails') {
        this.inputIsInvalid = !this.multipleEmailsValid(value);
        this.invalidMsg = this.inputIsInvalid ? 'Emails should be valid and separated by a comma' : '';
      } else {
        this.inputIsInvalid = false;
        this.invalidMsg = '';
      }
      this.validValue = !this.inputIsInvalid;
    },
    onInput(value) {
      if (!value) {
        this.invalidMsg = '';
        this.validValue = false;
      } else {
        this.validateInput(value, this.type);
      }
      this.$emit('update:modelValue', value, this.name, this.parent, this.requiredField, this.validValue);
    },
    /*     onFocus(inputValue) {
      this.$emit('focus', inputValue, this.name);
    }, */
    onExitFocus(inputValue) {
      this.unfocused = true;
      if (!inputValue) {
        if (this.requiredField) {
          this.invalidMsg = 'This field is required';
          this.inputIsInvalid = true;
        }
      } else {
        this.validateInput(inputValue, this.type);
      }
    },
    handleButton() {
      this.$emit('inputButton');
    },
  },
};
</script>

<style lang="scss" scoped>
.value-no-edit {
  word-wrap: break-word;
  width: 100%;
}
.ort-input {
  @extend .text-md--medium;
  width: var(--width);
  padding: 5px 0;

  .input-area {
    width: 100%;
  }
  .no-info {
    font-style: italic;
    color: $grey-300;
  }
  .label {
    padding-bottom: 10px;
    color: $grey-100;
    white-space: nowrap;
  }
  .bottom-label {
    margin-top: 3px;
    margin-left: 3px;
    .hint {
      @extend .text-sm--medium;
      color: $grey-200;
    }
    .danger {
      @extend .text-sm--medium;
      color: $danger;
    }
  }
  .input-area {
    @extend .text-sm--semibold;
    z-index: 1;
    display: flex;
    flex-direction: row;
    padding: 10px 14px;
    align-items: center;

    border-radius: 12px;
    border: 1px solid $grey-500;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    color: $black-color;

    &:focus-within {
      border: 1px solid $red-300;
      box-shadow: 0px 0px 0px 4px $red-500;
    }

    input {
      @extend .text-md--medium;
      width: 100%;
      background-color: transparent;
      border: none;

      &:focus {
        outline: none;
      }
    }

    .right-icon {
      margin-left: auto;
      margin-right: 0;
    }

    &-invalid {
      border: 1px solid $red-300 !important;
    }
    &-disabled {
      background: $grey-700;
      input {
        color: $grey-400 !important;
      }
      ::placeholder {
        color: $grey-400 !important;
      }
    }
    &-button {
      border-radius: 8px 0px 0px 8px;
    }
  }
  .parent-input-area-button {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}
.required-field-asterisc {
  color: $red-color;
}
</style>
