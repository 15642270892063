<template>
  <div
    class="entry-wrapper"
    :class="{ 'is-focused': isFocused }"
    tabindex="0"
    @click="handleClick"
    @focus="isFocused = true"
    @blur="isFocused = false"
  >
    <ORTInvestmentOfferAvatar
      v-if="logo"
      class="entry-logo"
      :imgPath="logo"
    />
    <span class="bold-text">{{ boldText }}</span>
    <span class="thin-text">{{ thinText }}</span>
    <ORTBadge
      v-if="badgeText"
      :text="badgeText"
      :color="badgeColor"
    />
  </div>
</template>
<script>
import { format } from 'date-fns';
import { axiosCore } from '@/plugins/axios';
import ORTBadge from '@/ort-lib/components/ORTBadge.vue';
import ORTDropdown from '@/ort-lib/components/ORTDropdown.vue';
import { setCompactAmount } from '@/ort-lib/utils/formatters.js';
import { getChoice, choicesToDict } from '@/ort-lib/utils/utils.js';
import ORTInvestmentOfferAvatar from '@/components/ort-lib/ORTInvestmentOfferAvatar.vue';

export default {
  name: 'SearchDropdownItem',
  components: {
    ORTBadge,
    ORTDropdown,
    ORTInvestmentOfferAvatar,
  },
  props: {
    entry: {
      type: Object,
      required: true,
    },
    entryType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      boldText: '',
      thinText: '',
      badgeText: '',
      badgeColor: '',
      logo: '',
      isFocused: false,
    };
  },
  computed: {
    
  },
  methods: {
    setCompactAmount,
    async getStartupLogo(startupId) {
      const response = await axiosCore.get(`files/image/startups/${startupId}/profile_image`);
      if (response.data) this.logo = response.data.at(-1).url;
    },
    getBadgeColor(badgeText) {
      switch (badgeText) {
        case 'Active': case 'Transfered':
          return 'green';
        case 'Pending': case 'Submitted':
          return 'yellow';
        case 'Inactive': case 'Rejected': case 'Refused': case 'Not Funded': case 'Payment Failed':
          return 'red';
        default:
          return 'blue';
      }
    },
    handleClick() {
      this.$emit('entry-clicked', this.entry, this.entryType);
    },
  },
  async mounted() {
    if (this.entryType === 'Startup') this.getStartupLogo(this.entry.id);
    if (this.entryType === 'Fundraising') {
      if (this.entry.startup_relationship.profile_image)
        this.logo = this.entry.startup_relationship.profile_image;
    }

    const partnerTypeChoices = choicesToDict(await getChoice("PartnerTypeChoices"));

    switch (this.entryType) {
      case 'Startup':
        this.boldText = this.entry.name;
        this.thinText = format(new Date(this.entry.creation_datetime), 'MMMM yyyy');
        if (this.entry.is_funded) this.badgeText = 'Funded';
        this.badgeColor = 'blue';
        break;
      case 'Partner':
        this.boldText = this.entry.name;
        this.thinText = setCompactAmount(this.entry.total_clients_committed, "EUR", true);
        this.badgeText = partnerTypeChoices[this.entry.type];
        this.badgeColor = 'blue';
        break;
      case 'Investor':
        this.boldText = this.entry.name;
        this.thinText = `${this.entry.investments_count} investment`;
        if (this.entry.investments_count !== 1) this.thinText += 's';
        this.badgeText = setCompactAmount(this.entry.investments_committed, "EUR", true);
        this.badgeColor = this.entry.investments_committed > 0 ? 'green' : 'grey';
        break;
      case 'Investment':
        this.boldText = `${this.entry.investor}'s investment in ${this.entry.startup}`;
        this.thinText = setCompactAmount(this.entry.committed_amount, this.entry.currency, true);
        this.badgeText = this.humanize(this.entry.status);
        this.badgeColor = this.getBadgeColor(this.badgeText);
        break;
      case 'Fundraising':
        this.boldText = this.entry.name;
        this.thinText = `${this.entry.investments_count} investment`;
        if (this.entry.investments_count !== 1) this.thinText += 's';
        this.badgeText = this.humanize(this.entry.status);
        this.badgeColor = this.getBadgeColor(this.badgeText);
        break;
      case 'CustomUser':
        this.boldText = this.entry.full_name;
        this.thinText = this.entry.email;
        this.badgeText = this.entry.is_active ? 'Active' : 'Inactive';
        this.badgeColor = this.getBadgeColor(this.badgeText);
        break;
      default:
        break;
    }
  },
};
</script>
<style lang="scss" scoped>
.entry-wrapper {
  display: flex;
  width: 100%;
  gap: 16px;
  align-items: center;
  // justify-content: space-between;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
  &:active {
    background-color: #e5e5e5;
  }
  &.is-focused {
    background-color: #f5f5f5;
  }
}
.bold-text {
  @extend .text-sm--bold;
}
.thin-text {
  @extend .text-sm--semibold;
  color: #8e8e93;
  white-space: nowrap;
}
.entry-logo {
  :deep(img) {
    max-width: 30px;
  }
}
</style>
