<template>
  <div class="p-3">
    <div v-if="loading" class="d-flex flex-column">
      <h4>Creating investor, please wait...</h4>
      <loading class="mx-auto mt-4" :active="loading" color="red"/>
    </div>
    <div v-else>
      <ORTMessage
        title="Creating an Investor will not send any email."
        text="To invite an investor, you must go to the related User's 'User Management' tab and invite the user."
        linkText="Follow this link for more information on Onboarding."
        link="https://www.notion.so/oneragtime/Onboarding-new-Clients-85ff73eb2805407f9cf1b292eede472e"
      />
      <form @submit.prevent="createInvestor">
        <div>
          <ORTInput
            name="name"
            placeholder="Investor name"
            :modelValue="body.name"
            @update:modelValue="updateBody"
            required
            edit
          />
          <ORTDropdown
            placeholder="Investor type"
            field="type"
            :options="investorTypeChoices"
            @input="updateBody"
            required
          />
        </div>
        <div class="mt-4 d-flex w-100">
          <ORTButton
            text="Cancel"
            width="100%"
            variant="secondary"
            @click="$emit('close')"
          />
          <ORTButton
            class="ml-2"
            width="100%"
            text="Create"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
/** @typedef {import("src/ort-lib/stores/relationships/relationships").Actions} RelationshipActions */

import { getAction } from '@/utils/jsdoc.js';
import ORTInput from '@/ort-lib/components/ORTInput.vue';
import ORTDropdown from '@/ort-lib/components/ORTDropdown.vue';
import ORTButton from '@/ort-lib/components/ORTButton.vue';
import ORTMessage from '@/components/ort-lib/ORTMessage.vue';
import { getChoice, errorAlert, successAlert } from '@/ort-lib/utils/utils';

export default {
  name: 'CreateInvestor',
  components: {
    ORTDropdown,
    ORTInput,
    ORTButton,
    ORTMessage,
  },
  props: {
    userToLink: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      body: {},
      loading: false,
      investorTypeChoices: [],
      actions: {
        addInvestorRelationship: getAction(/** @type {RelationshipActions["addInvestorRelationship"]} */ ('addInvestorRelationship')),
      },
    };
  },
  methods: {
    updateBody(value, field) {
      this.body[field] = value;
    },
    async createInvestor() {
      this.loading = true;
      try {
        const response = await this.apiPost('/investors/create', this.body);
        const investor = response.data;
        if (this.userToLink)
          await this.actions.addInvestorRelationship({ userId: this.userToLink, investorId: investor.id });
        successAlert('The investor was successfully created!');
        this.$emit('created', investor);
        this.$emit('close');
      } catch (error) {
        errorAlert('The investor was not created due to an error.', error);
      }
      this.loading = false;
    },
  },
  async mounted() {
    this.body = {};
    this.investorTypeChoices = await getChoice("InvestorTypeChoices");
  },
};
</script>
