<template>
  <div class="container-fluid">
    <div class="px-3">
      <div v-if="creating">
        <div class="text-center display-sm--semibold">
          <p>Creating startup, please wait...</p>
          <loading class="my-5"></loading>
        </div>
      </div>
      <div v-else>
        <div class="text-sm--semibold text-center mb-3 pointer" @click="setScrapedToNextKey" v-if="scraped.length">
          Not ok? <b>Click here</b> (we have {{ Object.keys(scraped).length }} different results)
        </div>
        <form @submit.prevent="createStartup">
        <div class="row modal-body">
          <div class="col-12 col-md-6">
            <ORTInput
              label="Startup name"
              :edit="true"
              placeholder="Startup name"
              name="name"
              :modelValue="body.name"
              @update:modelValue="updateBody"
              required
            />
            <ORTInput
              label="Startup address"
              :edit="true"
              name="address"
              placeholder="Startup address"
              :modelValue="body.address"
              @update:modelValue="updateBody"
              required
            />
            <ORTInput
              label="Revenue"
              :edit="true"
              name="revenue"
              placeholder="Revenue"
              :modelValue="body.revenue"
              @update:modelValue="updateBody"
            />
            <ORTInput
              label="Round Size"
              :edit="true"
              name="round_size"
              type="number"
              placeholder="Round Size"
              :modelValue="body.round_size"
              @update:modelValue="updateBody"
            />
            <ORTMultiSelect
              name="sectors"
              label="Sectors of interest"
              :options="selects.sectors"
              :values="body.sectors"
              @update:modelValue="updateBody"
              edit
            />
            <ORTInput
              :edit="true"
              label="Startup url"
              parent="links"
              placeholder="https://mycompany.io"
              name="website"
              required
              :modelValue="body.links?.website"
              @update:modelValue="updateBody"
            />
            <ORTDropdown
              :editable="true"
              label="GSI FDJ"
              field="GSI_FDJ"
              :options="selects.GSI_FDJs"
              :value="body.GSI_FDJ"
              @input="updateBody"
            />
            <ORTDropdown
              class="mt-2"
              :editable="true"
              label="SAE FDJ"
              field="SAE_FDJ"
              :options="selects.SAE_FDJs"
              :value="body.SAE_FDJ"
              @input="updateBody"
            />
          </div>
          <div class="col-12 col-md-6">
            <form-text-area
              :editable="true"
              label="Startup Tagline"
              :initialValue="body.tagline"
              @input="(value) => updateBody(value, 'tagline')"
              :rows="5"
            />
            <ORTInput
              label="Competitors"
              :edit="true"
              name="competitors"
              placeholder="Competitors"
              :modelValue="body.competitors"
              @update:modelValue="updateBody"
            />
            <ORTToggle
              title="Has female at a C-level"
              field="female_founder"
              :initialValue="body.female_founder"
              :options="options"
              @click="updateBody"
            />
            <ORTToggle
              class="mt-2"
              title="Impact"
              field="impact"
              :initialValue="null"
              :options="options"
              @click="updateBody"
            />
            <ORTToggle
              class="mt-2"
              title="Aria Scope"
              field="aria_scope"
              :initialValue="null"
              :options="options"
              @click="updateBody"
            />
            <ORTDropdown
              class="mt-2"
              :editable="true"
              label="Fundraising stage"
              field="stage"
              :options="selects.stages"
              :value="body.stage"
              @input="updateBody"
              required
            />
          </div>
        </div>
        <div class="my-4 d-flex">
          <ORTButton 
            variant="secondary" 
            class="mr-3"
            width="100%"
            text="Cancel"
            @click="$emit('close')"
          />
          <ORTButton 
            width="100%"
            text="Create"
          />
        </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { successAlert, errorAlert } from '@/ort-lib/utils/utils';
import { getChoice } from '@/ort-lib/utils/utils';
import ORTInput from '@/ort-lib/components/ORTInput.vue';
import ORTToggle from '@/ort-lib/components/ORTToggle.vue';
import ORTDropdown from '@/ort-lib/components/ORTDropdown.vue';
import ORTButton from '@/ort-lib/components/ORTButton.vue';
import ORTMultiSelect from '@/components/Pages/Startup/components/ORTMultiSelect.vue';

export default {
  compatConfig: { MODE: 3 },
  name: 'CreateStartup',
  components: {
    ORTDropdown,
    ORTInput,
    ORTToggle,
    ORTMultiSelect,
    ORTButton,
  },
  props: {
    url: {
      type: String,
      default: '',
    },
    scraped: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      stage: [],
      creating: false,
      scrapeIdx: 0,
      options: [
        { text: 'Yes', value: true },
        { text: 'Unknown', value: null, },
        { text: 'No', value: false, },
      ],
      body: {
        links: {
          website: undefined,
        },
        competitors: undefined,
        revenue: undefined,
        name: undefined,
        female_founder: false,
        address: undefined,
        sectors: [],
        stage: undefined,
        impact: null,
        aria_scope: null,
        GSI_FDJ: null,
        SAE_FDJ: null,
        round_size: null,
      },
      selects: {
        sectors: [],
        stages: [],
        GSI_FDJs: [],
        SAE_FDJs: [],
      },
    };
  },
  methods: {
    updateBody(value, field, parent) {
      if (parent) {
        this.body[parent][field] = value;
      } else {
        this.body[field] = value;
      }
    },
    createStartup() {
      this.creating = true;
      this.apiPost('/startups/create', this.body).then(
        (response) => {
          this.creating = false;
          successAlert("The Startup was successfully created!")
          this.$emit('created', response.data);
          this.$emit('next', response.data.id);
        },
        (e) => {
          this.creating = false;
          errorAlert('The Startup was not created due to an error.', e);
        },
      );
    },
    /** 
     * Cycling thru the keys of dict `this.scraped`
     */
    setScrapedToNextKey() {
      this.scrapeIdx = (this.scrapeIdx + 1) % Object.keys(this.scraped).length;
      this.scrapedSwitchDict();
    },
    scrapedSwitchDict() {
      if (!this.scraped[this.scrapeIdx]) return;
      this.body.links.website = this.scraped[this.scrapeIdx].website;
      this.body.name = this.scraped[this.scrapeIdx].name;
      this.body.address = this.scraped[this.scrapeIdx].address;
      this.body.female_founder = this.scraped[this.scrapeIdx].female_founder;
      this.body.revenue = this.scraped[this.scrapeIdx].revenue;
      this.body.competitors = this.scraped[this.scrapeIdx].competitors;
      this.body.tagline = this.scraped[this.scrapeIdx].tagline;
      this.body.stage = this.scraped[this.scrapeIdx].stage;
      this.body.sectors = this.scraped[this.scrapeIdx].sectors;
    },
  },
  async mounted() {
    this.selects.sectors = await getChoice('Sector');
    this.selects.stages = await getChoice('GrowthStageChoices');
    this.selects.GSI_FDJs = await getChoice('GSI_FDJ_Choices');
    this.selects.SAE_FDJs = await getChoice('SAE_FDJ_Choices');
    this.selects.sectors = await getChoice('StartupSectorChoices');
    this.scrapedSwitchDict();
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  color: $secondary;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.modal-body {
  max-height: 60vh;
  overflow-y: scroll;
}
</style>
