// Store actions

import { setUser } from '@sentry/vue';
import VueCookie from 'vue-cookies';
import router from '@/router';

import JwtToken from '@/plugins/jwt';
import { axiosCore } from '@/plugins/axios';

export default {
  async login(context, data) {
    // Request to get user logged in informations
    await axiosCore.get('/auth/users/me').then(
      (response) => {
        // Save user infos
        context.commit('saveUserInfos', response.data);

        // Set logged in state to true
        context.commit('setLoggedIn', true);
        router.push({ name: 'Finance' });
      },
    );

    return Promise.resolve();
  },
  logout(context, redirection = true) {
    // Remove user infos
    context.commit('removeUserInfos');

    // Set logged in state to false
    context.commit('setLoggedIn', false);

    setUser(null);

    if (redirection) {
      router.push({ name: 'Login', query: { logout: true } });
    }

    return Promise.resolve();
  },
  async join(context, data) {
    context.commit('saveUserInfos', data);

    const body = {
      email: data.email,
      password: data.password,
    };

    axiosCore.post('/auth/login/', body).then(
      (response) => {
        context.dispatch('login', response.data);
      },
      (response) => {},
    );
  },
  async searchInfos(context) {
    const raw_refresh_token = VueCookie.get('core_ort_jwt_refresh_token');
    let token_is_not_expired = false;

    if (raw_refresh_token) {
      const jwt_refresh_token = new JwtToken(raw_refresh_token);
      token_is_not_expired = !jwt_refresh_token.is_expired();
    }

    //  Check if there's a token (means that user is still logged in)
    if (token_is_not_expired) {
      // Set logged in state to true
      context.commit('setLoggedIn', true);

      await axiosCore.get('/auth/users/me').then(
        (response) => {
          // Save user infos
          context.commit('saveUserInfos', response.data);
          setUser({
            username: `${response.data.first_name} ${response.data.last_name} <${response.data.email}>`,
          });
        },
        (error) => {},
      );
    } else {
      context.dispatch('logout');
    }
    return Promise.resolve();
  },
  successAlert(context, message) {
    if (message || message.message) {
      if (typeof message === 'string') context.commit('changeAlertInfos', { type: 'success', show: true, message });
      else {
        console.warn('[WARN!] Success alert was called improperly');
        context.commit('changeAlertInfos', { type: 'success', show: true, message: message.message });
      }
    }
    return true;
  },
  dangerAlert(context, message) {
    if (message || message.message) {
      if (typeof message === 'string') context.commit('changeAlertInfos', { type: 'danger', show: true, message });
      else {
        console.warn('[WARN!] Danger alert was called improperly');
        context.commit('changeAlertInfos', { type: 'danger', show: true, message: message.message });
      }
    }
    return false;
  },
  infoAlert(context, message) {
    if (message || message.message) {
      if (typeof message === 'string') context.commit('changeAlertInfos', { type: 'info', show: true, message });
      else {
        console.warn('[WARN!] Info alert was called improperly');
        context.commit('changeAlertInfos', { type: 'info', show: true, message: message.message });
      }
    }
  },
  triggerAlert(context, alert) {
    context.commit('changeAlertInfos', alert);
  },
  cacheData(context, data) {
    context.commit('cacheData', data);
  },
};
