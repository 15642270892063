// Functional modules
import Vue, { createApp } from 'vue';
import axios from 'axios';
import AsyncComputed from 'vue-async-computed';
import moment from 'moment';
import VueDatePicker from '@vuepic/vue-datepicker';
import App from './App.vue';

// Global imports
import '@/assets/css/bootstrap_custom.scss';
import * as Sentry from '@sentry/vue';

import {
  // Directives
  VTooltip,
  VClosePopper,
  // Components
  Dropdown,
  Tooltip,
  Menu,
} from 'floating-vue';
import { store } from '@/store/index.js';
import { router } from '@/router/index.js';
import configPlugin from '@/config';
import 'floating-vue/dist/style.css';
import 'vue2-animate/dist/vue2-animate.min.css';

// Global directives
import click_outside from '@/components/lib/directives/click_outside.js';
import focused from '@/components/lib/directives/focused.js';
import unfocused from '@/components/lib/directives/unfocused.js';

// Global components
import PlatformLoading from '@/components/style/Loading.vue';
import PlatformPicture from '@/components/lib/images/Picture.vue'; // './images/Picture';
import PlatformButton from '@/components/lib/buttons/Button.vue';
import PlatformLink from '@/components/lib/buttons/Link.vue';
import PlatformDropdown from '@/components/lib/buttons/Dropdown.vue';
import PlatformAlert from '@/components/lib/alerts/Alert.vue';
import PlatformTagLabel from '@/components/lib/labels/TagLabel.vue';
import PlatformModal from '@/components/lib/modals/Modal.vue';

// Forms
import FormTextArea from '@/components/lib/forms/FormTextArea.vue';

// NEW FORMS FOR REVAMP
import NewFormRadio from '@/components/lib/new_forms/FormRadio.vue';

import animateLogo from './directives/animate_logo.js';
import utils from '@/components/lib/mixins/utils.js';
import request_handler from './components/lib/mixins/request_handler';

// datepicker
import '@vuepic/vue-datepicker/dist/main.css';

Vue.configureCompat({ WATCH_ARRAY: false });

// CUSTOM CONFIGS

const app = createApp(App);

app.config.globalProperties.moment = moment;

app.use(AsyncComputed);

app.directive('click-outside', click_outside);
app.directive('focused', focused);
app.directive('unfocused', unfocused);

app.directive('tooltip', VTooltip);
app.directive('close-popper', VClosePopper);

app.component('VueDatePicker', VueDatePicker);

app.component('VDropdown', Dropdown);
app.component('VTooltip', Tooltip);
app.component('VMenu', Menu);

app.component('loading', PlatformLoading);
app.component('pic', PlatformPicture);
app.component('btn', PlatformButton);
app.component('direct-link', PlatformLink);
app.component('dropdown', PlatformDropdown);
app.component('alert', PlatformAlert);
app.component('tag-label', PlatformTagLabel);
app.component('modal', PlatformModal); // asdf
app.component('form-text-area', FormTextArea);
app.component('new-form-radio', NewFormRadio);

app.directive('animate-logo', animateLogo);

// Registering global mixins:
app.mixin(utils);
app.mixin(request_handler);

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';

app.config.globalProperties.$axios = axios;

app.config.globalProperties.$appConfig = configPlugin;

Sentry.init({
  app,
  dsn: 'https://24c4500101125ed213c60f45bbe0b210@o351729.ingest.us.sentry.io/4506971495923712',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: ['*'],
    }),
  ],
  environment: import.meta.env.VITE_ENV,
  // Don't send errors to Sentry in local development
  enabled: import.meta.env.VITE_ENV !== 'local',
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['*'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(store);
app.use(router);

router.isReady().then(() => {
  app.mount('#app');
});
