<template>
  <div class="wrapper">
    <span class="ort-dropdown__label text-sm--bold title">{{ label }}</span>
    <div v-if="edit" tabindex="0" :class="['ort-dropdown', { disabled }]">
      <div
        v-if="values.length"
        class="ort-dropdown--selected"
        @click="toggleOptions"
        :class="{open: dropdownOpen, disabled}"
      >
        <div class="options-selected">
          <ORTBadge
            v-for="value in values"
            :key="value"
            :text="choicesDict[value]"
            class="tag"
            color="grey"
          >
            <button @click.stop="removeChoice(value)" class="ml-1 button-no-style">
              <img :src="xCloseIcon" alt="xCloseIcon" class="ml-1 button-no-style"/>
            </button>
          </ORTBadge>
        </div>
        <div class="arrow-down">
          <i class="fas" :class="dropdownOpen ? 'fa-chevron-up' : 'fa-chevron-down'"/>
        </div>
      </div>
      <div
        v-else
        @click="toggleOptions"
        :class="['ort-dropdown--unselected', {'open': dropdownOpen, disabled}]"
      >
        Select
        <div v-if="!dropdownOpen">
          <i class="fas fa-chevron-down"/>
        </div>
        <div v-else>
          <i class="fas fa-chevron-up"/>
        </div>
      </div>
      <div :class="['dropdown-wrapper', {'dropdown-wrapper--hidden': !dropdownOpen}]">
        <div
          v-for="option of filteredOptions"
          :key="option.value"
          class="dropdown-item"
          @click="addChoice(option.value)"
        >
          {{ option.text }}
        </div>
      </div>
    </div>
    <div v-else class="mt-3">
      <div v-if="values.length" class="options-selected">
        <ORTBadge v-for="value in valuesResolved" color="purple" class="m-1">
          {{ value }}
        </ORTBadge>
      </div>
      <div v-else>
        <span class="no-info">No info</span>
      </div>
    </div>
    <span class="ort-dropdown__hint-text">{{ hint }}</span>
  </div>
</template>

<script>
/** @template T; @typedef {import("vue").PropType<T>} PropType<T> */
/** @typedef {import("@/ort-lib/types/general/types").Choice} Choice */

import ORTBadge from '@/ort-lib/components/ORTBadge.vue';

export default {
  name: 'ORTMultiSelect',
  components: {
    ORTBadge,
  },
  props: {
    name: {
      type: String,
      required: false,
    },
    values: {
      type: Array,
      default: [],
    },
    options: {
      /** @type {PropType<Choice[]>} */
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      required: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: { // TODO
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      xCloseIcon: new URL('@/ort-lib/assets/icons/x-close.svg', import.meta.url).href,
      dropdownOpen: false,
    };
  },
  computed: {
    filteredOptions() {
      return this.options.filter((option) => !this.values.includes(option.value));
    },
    choicesDict() {
      return this.options.reduce((acc, choice) => {
        acc[choice.value] = choice.text;
        return acc;
      }, {});
    },
    valuesResolved() {
      return this.values.map((value) => this.choicesDict[value]);
    },
  },
  methods: {
    removeChoice(value) {
      if (this.disabled) return;
      this.dropdownOpen = false;
      let newValues = this.values.filter((val) => val !== value);
      this.$emit('update:modelValue', newValues, this.name, this.parent);
    },
    addChoice(value) {
      if (this.disabled) return;
      let newValues = [...this.values, value];
      this.$emit('update:modelValue', newValues, this.name, this.parent);
    },
    toggleOptions() {
      if (this.disabled) return;
      this.dropdownOpen = !this.dropdownOpen;
    },
  },
  watch: {
    edit(newVal) {
      if (!newVal) {
        this.dropdownOpen = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 5px 0;
}

.ort-dropdown {
  @extend .text-md--medium;
  position: relative;
  border: 1px solid $grey-500;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 12px;

  &:focus {
    border: 1px solid $cobalt-300;
    box-shadow: 0px 0px 0px 4px $cobalt-600;
  }

  &__label {
    @extend .text-sm--semibold;
  }

  &__hint-text {
    @extend .text-sm--medium;
    color: $grey-200;
  }

  &--selected, &--unselected {
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 14px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  }

  &--unselected {
    color: $grey-200;
  }
}

.disabled {
  cursor: not-allowed;
  background-color: $grey-700;
  color: $grey-400;
  border-color: $grey-400;
}

.dropdown-wrapper {
  &:last-child {
    margin-bottom: 0;
  }

  &--hidden {
    display: none;
  }

  overflow: hidden;
  position: absolute;
  z-index: 999999;
  left: 0;
  right: 0;
  padding: 6px;
  margin: 5px;
  max-height: 280px;
  overflow-y: auto;
  background-color: $white-color;
  border: 1px solid $grey-600;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 12px;
}

.dropdown-item {
  cursor: pointer;
  padding: 10px 8px;
  margin-bottom: 4px;
  color: $grey-100;

  &:hover {
    background: $cobalt-500;
    border-radius: 12px;
  }
}

.tag {
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 5px;
  height: 27px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
}

.button-no-style {
  width: fit-content;
  height: 100%;
  margin-bottom: 5px;
  border: none;
}

.options-selected {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.no-info {
  font-style: italic;
  color: $grey-300;
}
</style>
