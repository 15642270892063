<template>
  <div class="main-wrapper">
    <div class="navbar-logo" @click="goToFinanceDashboard">
      <img
        v-animate-logo
        id="logo"
        class="d-block mx-auto img-fluid"
        src="@/ort-lib/assets/images/CORE_LOGO_full_color.png"
        alt="Core logo"
        width="32"
        height="32"
      />
    </div>
    <ORTTooltip text="Dashboards" left="200%">
      <div :class="`${active === 'Dashboards' ? 'active': ''} icon`" @click="setActiveIcon('Dashboards')">
        <img :src="dashboardIcon" alt="Dashboard Icon">
      </div>
    </ORTTooltip>
    <ORTTooltip text="Finance" left="200%">
      <div :class="`${active === 'Finance' ? 'active': ''} icon`" @click="setActiveIcon('Finance')">
        <img :src="currencyIcon" alt="Currency Icon">
      </div>
    </ORTTooltip>
    <ORTTooltip text="Investors" left="200%">
      <div :class="`${active === 'Investors' ? 'active': ''} icon`" @click="setActiveIcon('Investors')">
        <img :src="usersIcon" alt="Users Icon">
      </div>
    </ORTTooltip> 
    <ORTTooltip text="Dealflow" left="200%">
      <div :class="`${active === 'Dealflow' ? 'active': ''} icon`" @click="setActiveIcon('Dealflow')">
        <img :src="rocketIcon" alt="Rocket Icon">
      </div>
    </ORTTooltip>
    <ORTTooltip 
      text="Settings"
      left="200%"
      class="settings"
    >
      <div :class="`${active === 'Settings' ? 'active': ''} icon`" @click="setActiveIcon('Settings')">
        <img :src="logOutIcon" alt="Log Out Icon">
      </div>
    </ORTTooltip>
  </div>
</template>
<script>
import ORTTooltip from '@/ort-lib/components/ORTTooltip.vue';
export default {
  name: 'MainSidebarMenu',
  components: {
    ORTTooltip,
  },
  data() {
    return {
      dashboardIcon: new URL('@/ort-lib/assets/icons/barChartSquare.svg', import.meta.url).href,
      usersIcon: new URL('@/ort-lib/assets/icons/users-01.svg', import.meta.url).href,
      rocketIcon: new URL('@/ort-lib/assets/icons/rocket-02.svg', import.meta.url).href,
      currencyIcon: new URL('@/ort-lib/assets/icons/currency-euro-circle.svg', import.meta.url).href,
      logOutIcon: new URL('@/ort-lib/assets/icons/settings-01.svg', import.meta.url).href,

      activeIcon: '',
    }
  },
  computed: {
    active() {
      if (this.activeIcon) return this.activeIcon;
      const path = this.$route.path.toLowerCase();
      if(path.startsWith('/finance')) {
        this.activeIcon = 'Finance';
      } else if(path.startsWith('/investors')) {
        this.activeIcon = 'Investors';
      } else if(path.startsWith('/dealflow')) {
        this.activeIcon = 'Dealflow';
      } else if(path.startsWith('/settings')) {
        this.activeIcon = 'Settings';
      } else {
        this.activeIcon = 'Dashboards';
      }
      return this.activeIcon;
    },
  },
  methods: {
    setActiveIcon(icon) {
      this.activeIcon = icon;
    },
    goToFinanceDashboard() {
      this.setActiveIcon('Dashboards');
      this.$router.push({ name: 'Finance' });
      this.$emit('closeSidebar');
    },
  },
  watch: {
    activeIcon(newValue) {
      this.$emit('activeIcon', newValue);
    }
  },
}
</script>
<style lang="scss" scoped>
.main-wrapper {
  width: 68px;
  border-radius: 12px;
  background-color: #F1EEEE;
  height: 100%;
  padding: 24px 18px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .navbar-logo {
    margin-bottom: 24px;
    cursor: pointer;
  }

  .icon {
    margin-bottom: 4px;
    width: 48px;
    height: 48px;
    cursor: pointer;
    padding: 14px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: $white;
      opacity: 0.7;
    }
  }
  .settings {
    margin-top: auto;
  }
  .active {
    background-color: $white;
  }
}
</style>