<template>
  <div class="logo-circle">
    <img :src="imgLogo"/>
  </div>
</template>

<script>

export default {
  name: 'ORTInvestmentOfferAvatar',
  props: {
    imgPath: {
      type: String,
      required: false,
    },
    imgName: {
      type: String,
      required: false,
    },
  },
  computed: {
    imgLogo() {
      if (this.imgPath)
        return new URL(this.imgPath, import.meta.url).href;
      if (this.imgName)
        return this.getImg(this.imgName);
      return '';
    },
  },
  methods: {
    getImg(imgName) {
      switch (imgName) {
        case 'Paragon':
          return new URL('@/assets/icons/paragon-logo.svg', import.meta.url).href;
        case 'Club Deal':
          return new URL('@/assets/icons/clubdeal-logo.svg', import.meta.url).href;
        case 'Corporate':
        case 'OneRagtime Aria':
          return new URL('@/assets/icons/corporate-logo.svg', import.meta.url).href;
        case 'Rhapsody I':
          return new URL('@/assets/icons/rhapsodyi-logo.svg', import.meta.url).href;
        case 'Rhapsody II':
          return new URL('@/assets/icons/rhapsodyii-logo.svg', import.meta.url).href;
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.logo {
  max-width: 28px;
  &-circle {
    @include make-flex(center, center, auto);
    background-color: $white-color;
    width: 30px;
    height: 30px;
    font-size: 15px;
    border-radius: 50%;
    box-shadow: 0 1px 4px 0 $grey-color;
    color: $black-grey-color;
  }
}
</style>
