<template>
  <div class="ort-input">
    <div v-if="label" class="label text-sm--bold">
      <span v-if="required" class="text text-danger">* </span>
      <span>{{ label }}</span>
    </div>
    <div v-if="!edit">
      <slot name="display-value">
        <div v-if="modelValue" class="text-sm">
          {{ modelValue }} {{ suffix }}
        </div>
        <div v-else class="text-md no-info">No info</div>
      </slot>
    </div>
    <div v-if="edit">
      <input
        ref="input"
        :type="type"
        :pattern="pattern"
        :value="modelValue"
        @input="onInput($event.target.value)"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="required"
      />
    </div>
    <span v-if="hint" class="hint">{{ hint }}</span>
  </div>
</template>

<script>
export default {
  compatConfig: { MODE: 3 },
  name: 'ORTInput',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: [String, Number, Boolean],
      default: '',
    },
    name: {
      type: String,
      required: false,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      required: false,
    },
    hint: {
      type: String,
      required: false,
    },
    width: {
      type: String,
      default: '100%',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    parent: {
      type: String,
      required: false,
    },
    suffix: {
      type: String,
      default: '',
    },
    // for validation:
    required: {
      default: false,
      type: Boolean,
    },
    type: {
      // regular: [text, email, number]; custom: [multiple_emails]
      default: 'text',
      type: String,
    },
    pattern: {
      type: String,
      default: '.*',
    },
  },
  methods: {
    emailIsValid(email) {
      const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
      return emailRegex.test(email);
    },
    validateInput(value) {
      switch (this.type) {
        case 'multiple_emails':
          const emailsArray = value.split(',').map((email) => email.trim());
          const validEmails = emailsArray.reduce((boolean, email) => this.emailIsValid(email), true);
          return validEmails ? '' : 'Emails should be valid and separated by a comma';
        default:
          return '';
      }
    },
    onInput(value) {
      this.$refs.input.setCustomValidity('');
      if (this.$refs.input.checkValidity()) {
        const result = this.validateInput(this.$refs.input.value);
        this.$refs.input.setCustomValidity(result);
      }
      this.$emit('update:modelValue', value, this.name, this.parent, this.$refs.input.validationMessage);
    },
  },
};
</script>

<style lang="scss" scoped>
.no-info {
  font-style: italic;
  color: $grey-300;
}

.ort-input {
  @extend .text-md--medium;
  width: v-bind(width);
  padding: 5px 0;

  .hint {
    @extend .text-sm--medium;
    color: $grey-200;
  }

  input {
    width: 100%;
    border-radius: 12px;
    padding: 10px 14px;
    outline: none;
    border: 1px solid $grey-500;
    box-shadow: 0px 1px 2px $cobalt-400; // rgba(16, 24, 40, 0.05)

    &:valid {
      border: 1px solid $grey-500;
    }

    &:invalid {
      border: 1px solid $red-300;
    }

    &:focus-within {
      border: 1px solid $cobalt-300; // $red-300
      box-shadow: 0px 0px 0px 4px $cobalt-600; // $red-500
    }

    &:invalid:focus-within {
      border: 1px solid $red-300;
      box-shadow: 0px 0px 0px 4px $red-500;
    }
  }
}
</style>
