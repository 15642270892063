import { axiosCore } from '@/plugins/axios';

const state = {
  allInvestors: [],
  IsAllInvestorsTableLoading: false,
  allInvestorsCount: 0,
  InvestorTypeChoices: [],
  KYCStatusChoices: [],
  investorsCreatedDashboard: [],
};

const getters = {
  getAllInvestors: (state) => state.allInvestors,
  getIsAllInvestorsTableLoading: (state) => state.IsAllInvestorsTableLoading,
  getAllInvestorsCount: (state) => state.allInvestorsCount,
  getInvestorTypeChoices: (state) => state.InvestorTypeChoices,
  getKYCStatusChoices: (state) => state.KYCStatusChoices,
  getInvestorsCreatedDashboard: (state) => state.investorsCreatedDashboard,
};

const mutations = {
  setAllInvestors: (state, data) => {
    state.allInvestors = data;
  },
  setIsAllInvestorsTableLoading: (state, payload) => {
    state.IsAllInvestorsTableLoading = payload;
  },
  setAllInvestorsCount: (state, payload) => {
    state.allInvestorsCount = payload;
  },
  setInvestorTypeChoices: (state, payload) => {
    state.InvestorTypeChoices = payload;
  },
  setKYCStatusChoices: (state, payload) => {
    state.KYCStatusChoices = payload;
  },
  setInvestorsCreatedDashboard: (state, data) => {
    state.investorsCreatedDashboard = data;
  },
};

const actions = {
  async fetchAllInvestors({ commit, dispatch }, {
    perPage, page, searchParam, selectedFilters, order,
  }) {
    commit('setIsAllInvestorsTableLoading', true);
    try {
      const response = await axiosCore.get(`/investors/list-core?${searchParam}page=${page}&page_size=${perPage}${selectedFilters}${order}`);
      commit('setAllInvestors', response.data?.results);
      commit('setAllInvestorsCount', response.data?.count);
    } catch (error) {
      dispatch('triggerAlert', {
        show: true,
        type: 'danger',
        message: error.message,
      });
    } finally {
      commit('setIsAllInvestorsTableLoading', false);
    }
  },
  async exportInvestorsTable({ dispatch }, {
    perPage, page, searchParam, selectedFilters, order,
  }) {
    try {
      const response = await axiosCore.get(`/investors/list-core?${searchParam}page=${page}&page_size=${perPage}${selectedFilters}${order}&format=csv`);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'export.csv');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      dispatch('triggerAlert', {
        show: true,
        type: 'danger',
        message: error.message,
      });
    }
  },
  async fetchInvestorsCreatedDashboard({ commit }, yearParam) {
    try {
      const params = yearParam || '';
      const response = await axiosCore.get(`/dashboard/investors-created?${params}`);
      commit('setInvestorsCreatedDashboard', response.data);
    } catch (err) {
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
