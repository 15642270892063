<template>
  <div>
    <p class="text-sm--semibold">
      <span v-if="required" class="text text-danger">* </span>
      {{ title }}
    </p>
    <input
      style="opacity: 0; position: absolute; z-index: -1;"
      :name="field"
      :value="current"
      :required="required"
    />
    <div class="wrapper">
      <div
        v-for="(option, idx) in options"
        :key="option.value"
        :class="[...this.getBtnClass(idx), {'current': current === option.value}]"
        @mousedown="click(option.value)"
      >
        {{ option.text }}
      </div>
    </div>
</div>

</template>

<script>

export default {
  name: 'toggle',
  emits: ['click'],
  props: {
    title: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
    },
    initialValue: {
      type: [String, Boolean],
      default: '',
    },
    field: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      current: this.initialValue,
    };
  },
  methods: {
    click(value) {
      if (this.current !== value) {
        this.current = value;
        this.$emit('click', value, this.field);
      }
    },
    getBtnClass(idx){
      let classes = ['button'];
      if (idx === 0)
        classes.push('left');
      else if (idx === this.options.length - 1)
        classes.push('right');
      else
        classes.push('center');
      return classes;
    },
  },
  watch: {
    initialValue(value) {
      this.current = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.button {
  text-align: center;
  cursor: pointer;
  display: block;
  min-width: 100px;
  border: 1px solid $grey-500;
  padding: 4px 12px;
  background-color: $white;
  &:hover,
  &:active {
    background-color: $grey-700;
  }
  @extend .text-sm--bold
}
.left {
  border-radius: 10px 0px 0px 10px;
}
.center {
  border-left: none;
  border-right: 1px solid $grey-500;
}
.right {
  border-radius: 0px 10px 10px 0px;
  border-left: none;
}
.current {
  background-color: $grey-700;
}
</style>
