<template>
  <button
    v-bind="type && { type }"
    :class="buttonClasses"
    :style="cssStyles"
    @mousedown="onClick"
    :disabled="disabled"
    class="border-none"
  >
    <div v-if="!loading">
      {{ text }}
      <slot></slot>
    </div>
    <img v-else :src="loadingIcon" alt="loadingIcon" class="ort-button__icon icon-loading">
  </button>
</template>

<script>
import { type } from 'jquery';

//import loadingIcon from '@/assets/icons/loading-02.svg';
export default {
  name: 'ORTButton',
  emits: ['click'],
  props: {
    text: {
      type: [String, Number],
    },
    size: {
      type: String,
      validator: (value) => ([
        'sm',
        'md',
        'lg',
        'xl',
        'xxl',
      ].includes(value)),
      default: 'md',
    },
    disabled: {
      type: Boolean,
    },
    icon: {
      type: String,
    },
    iconPosition: {
      type: String,
      validator: (value) => ([
        'left',
        'right',
      ].includes(value)),
      default: 'left',
    },
    variant: {
      type: String,
      validator: (value) => ([
        'primary',
        'soft',
        'secondary',
        'danger',
        'filter',
        'grey',
      ].includes(value)),
      default: 'primary',
    },
    // behavior of the button
    type: {
      type: String,
      validator: (value) => ([
        'button',
        'reset',
        'submit',
      ].includes(value)),
      required: false,
    },
    width: {
      type: String,
      default: 'fit-content',
    },
    maxWidth: {
      type: String,
      default: 'none',
    },
    minWidth: {
      type: String,
      default: 'auto',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      loadingIcon: new URL('@/assets/icons/loading-02.svg', import.meta.url).href,
    }
  },
  computed: {
    cssStyles() {
      return {
        '--width': this.width,
        '--max-width': this.maxWidth,
        '--min-width': this.minWidth,
      };
    },
    buttonClasses() {
      return [
        'ort-button',
        `ort-button--${this.size}`,
        `ort-button--${this.variant}`,
        {
          'ort-button--reversed-icon-position': this.iconPosition === 'right',
        },
        `${ this.active ? 'active' : ''}`,
      ];
    },
  },
  methods: {
    onClick() {
      if (!this.loading) {
        this.$emit('click');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/_colors.scss';

.ort-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: var(--width);
  max-width: var(--max-width);
  min-width: var(--min-width);
  border: none;
  font-weight: 700;

  &__icon {
    margin-right: 8px;
    width: 20px !important;
    height: 20px !important;
    min-width: 20px !important;
  }

  &--reversed-icon-position {
    flex-direction: row-reverse;

    .ort-button__icon {
      margin-right: 0;
      margin-left: 8px;
    }
  }

  &--primary {
    background-color: $red-500;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    color: $secondary;

    &:hover {
      background-color: $red-450;
    }

    &:active {
      background-color: $red-400;
    }

    &:disabled {
      background-color: $red-500;
      color: $red-400;
      pointer-events: none;
    }
  }
  &--primary.active {
    background-color: $red-400;
    &:hover {
      background-color: $red-400;
    }
  }

  &--soft {
    background-color: unset;
    color: $secondary;

    &:hover {
      background-color: $red-450;
    }

    &:active {
      background-color: $red-400;
    }

    &:disabled {
      background-color: $red-500;
      color: $red-400;
      pointer-events: none;
    }
  }
  &--soft.active {
    background-color: $red-400;
  }
  &--filter {
    background-color: #FDF0EF;
    color: $secondary;
    box-shadow: 3px 3px 6px rgba(0,0,0, 0.3);

    &:hover {
      background-color: $red-450;
      box-shadow: inset 3px 3px 6px rgba(0,0,0, 0.2);
    }

    &:active {
      background-color: $red-400;
      box-shadow: inset 3px 3px 6px rgba(0,0,0, 0.2);
    }

    &:disabled {
      background-color: $red-500;
      color: $red-400;
      pointer-events: none;
    }
  }
  &--filter.active {
    background-color: $red-400;
    box-shadow: inset 3px 3px 6px rgba(0,0,0, 0.2);
  }

  &--secondary {
    background-color: $white;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid $grey-300;
    color: $grey-100;

    &:hover {
      background-color: $grey-700;
      border: 1px solid $grey-500;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    }

    &:focus {
      background-color: $white;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F2F4F7;
    }

    &:active {
      background-color: $white;
      border: 1px solid $grey-400;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F2F4F7;
    }

    &:disabled {
      background-color: $white;
      border: 1px solid $grey-500;
      color: $grey-500;
      pointer-events: none;

      & .ort-button__icon {
        background-color: $grey-500 !important;
      }
    }
  }
  &--grey {
    background-color: $white;
    color: #344054;

    &:hover {
      background-color: $grey-700;
    }
    &:active {
      background-color: $grey-700;
    }

    &:disabled {
      pointer-events: none;
      color: $grey-300;
    }
  }
  &--grey.active {
    background-color: $grey-600;
  }
  &--danger {
    background-color: $white;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid $error-300;
    color: $error-700;

    & .ort-button__icon {
      background-color: $error-700 !important;
    }

    &:hover {
      background-color: $error-50;
      border: 1px solid $error-300;
    }

    &:focus {
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px $error-100;
    }

    &:active {
      background-color: $error-100;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px $error-100;
    }

    &:disabled {
      border: 1px solid $error-200;
      pointer-events: none;
      color: $red-300;

      & .ort-button__icon {
        background-color: $error-300 !important;
      }
    }
  }

  &--sm {
    min-width: 47px;
    min-height: 36px;
    padding: 8px 14px;
    font-size: 14px;
  }

  &--md {
    min-width: 51px;
    min-height: 40px;
    padding: 10px 16px;
    font-size: 14px;
  }

  &--lg {
    min-width: 55px;
    min-height: 44px;
    padding: 10px 18px;
    font-size: 16px;
  }

  &--xl {
    min-width: 55px;
    min-height: 48px;
    padding: 12px 20px;
    font-size: 16px;
  }

  &--xxl {
    min-width: 55px;
    min-height: 60px;
    padding: 16px 28px;
    font-size: 18px;

    & .ort-button__icon {
      margin-right: 14px;
      width: 24px !important;
      height: 24px !important;
      min-width: 24px !important;
    }

    &.ort-button--reversed-icon-position {
      flex-direction: row-reverse;

      .ort-button__icon {
        margin-right: 0;
        margin-left: 14px;
      }
    }
  }
}

.icon-loading {
  animation: rotation 1s linear infinite;
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
