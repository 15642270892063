<template>
    <div class="">
      <div>
        <div class="px-5">
          <div v-show="creating">
            <div>
              <h4>Creating investment, please wait...</h4>
            </div>
          </div>
          <div v-show="loading">
            <div>
              <h4>Loading, please wait...</h4>
            </div>
          </div>
          <div v-show="loading || creating" class="d-flex justify-center mb-3">
            <loading class="mx-auto mt-4" :loading="loading || creating" color="red"></loading>
          </div>
          <div>
            <div v-show="!loading && !creating && !nextPage">
              <div>
                <span class="mini-title">Select the related investor</span>
                <ORTSearchDropdown
                  @input="searchInvestors"
                  @chooseOption="selectInvestor"
                  :options="optionsInvestors"
                  placeholder="Type here the investor name"
                  class="mt-3"
                />
              </div>
              <div v-if="investor_selected" class="mt-4">
                <ORTDropdown
                    label="Select the investor's related user"
                    labelStyle="bold"
                    labelSize="md--"
                    :options="users"
                    @input="selectUser"
                  />
                <span v-if="users.length === 0"
                  >This investor doesn’t seem to have any related user. First add a user to the team
                  and then try again to create the investment.</span
                >
              </div>
              <div v-if="user_selected" class="mt-4">
                <span class="mini-title d-block mb-3">Select the related fundraising</span>
                <ORTSearchDropdown
                  :options="optionsFundraisings"
                  @input="searchFundraisings"
                  @chooseOption="selectFundraising"
                />
              </div>
            </div>
            <div class="row" v-show="!loading && !creating && nextPage">
              <div class="col">
                <div class="row mt-0 flex-column">
                  <div class="col-12">
                    <span class="label">Investor</span>
                    <div class="selected-container mb-3">
                      <pic
                        class="selected-picture"
                        v-if="!loading"
                        :type="'profile-picture'"
                        :fluid="true"
                        :src="selectedInvestorObj.profile_image"
                        :src-blank="avatarNatural"
                      ></pic>
                      <span class="selected-name">{{ selectedInvestorObj.name }}</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <span class="label">Fundraising</span>
                    <div class="selected-container">
                      <pic
                        class="selected-picture"
                        v-if="!loading"
                        :type="'profile-picture'"
                        :fluid="true"
                        :src="selectedFundraisingObj.profile_image"
                        :src-blank="avatarLegal"
                      ></pic>
                      <span class="selected-name">{{ selectedFundraisingObj.name }}</span>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-12 col-md-6">
                    <ORTInput
                     label="Committed amount"
                     placeholder="1000000"
                     :modelValue="create_body.committed_amount"
                     @update:modelValue="(value) => create_body.committed_amount = value "
                     :edit="true"
                     type="number"
                    />
                    <div v-if="!create_body.committed_amount" class="validation-msg">This field is mandatory!</div>
                  </div>
                  <div class="ml-3" cols="12" md="6">
                    <label class="text-sm--bold">Status<i> (Default)</i></label>
                    <span class="d-block mt-1">Validated</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="!loading && !creating" class="row my-4">
            <div class="col" v-show="!nextPage"
              ><btn size="fluid" :color="continue_btn_color" @click="toNextStep">Next</btn></div
            >
            <div class="col"><btn size="fluid" color="dark-grey" @click="hideModal">Cancel</btn></div>
            <div class="col" v-show="nextPage"
              ><btn
                size="fluid"
                :color="create_body.committed_amount ? 'red-gradient' : 'grey'"
                @click="!create_body.committed_amount ? null : createInvestment()"
                :disabled="!create_body.committed_amount"
                >Create</btn
              ></div
            >
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import ORTSearchDropdown from '@/components/ort-lib/ORTSearchDropdown.vue';
import NoAvatarNatural from '@/assets/images/no-avatar-natural.png';
import NoAvatarLegal from '@/assets/images/no-avatar-legal.png';
import ORTInput from '@/components/ort-lib/ORTInput.vue';
import ORTDropdown from '@/ort-lib/components/ORTDropdown.vue';
import { errorAlert, successAlert } from '@/ort-lib/utils/utils';

export default {
  name: 'CreateInvestment',
  components: {
    ORTSearchDropdown,
    ORTInput,
    ORTDropdown,
  },
  data() {
    return {
      avatarNatural: NoAvatarNatural,
      avatarLegal: NoAvatarLegal,
      nextPage: false,
      loading: false,
      creating: false,
      fundraising_query: '',
      investor_query: '',
      user_query: '',
      fundraisings: [],
      investors: [],
      users: [''],
      optionsInvestors: [],
      optionsFundraisings: [],
      currencies: [''],
      selectedFundraisingObj: {
        profile_image: '',
      },
      selectedInvestorObj: {},
      selectedUserObj: {},
      create_body: {
        committed_amount: null,
        status: 'validated', // Always
        creator_user: null,
      },
    };
  },
  computed: {
    investor_selected() {
      const investorQueryLength = this.investor_query?.length > 1;
      const sameInvestorName = this.investor_query == this.selectedInvestorObj.name;
      const emptyInvestor = this.isEmpty(this.selectedInvestorObj);

      if (investorQueryLength && sameInvestorName && !emptyInvestor)
        return true;
      return false;
    },
    user_selected() {
      const emptyUser = this.isEmpty(this.selectedUserObj);
      if (!emptyUser) {
        return true;
      }
      return false;
    },
    fundraising_selected() {
      const fundraisingQueryLength = this.fundraising_query?.length > 1;
      const sameFundraisingName = this.fundraising_query == this.selectedFundraisingObj.name;
      const emptyFundraising = this.isEmpty(this.selectedFundraisingObj);
      if (fundraisingQueryLength && sameFundraisingName && !emptyFundraising) {
        return true;
      }
      return false;
    },
    all_selected() {
      if (this.investor_selected && this.user_selected && this.fundraising_selected) {
        return true;
      }
      return false;
    },
    continue_btn_color() {
      if (this.all_selected) {
        return 'red-gradient';
      }
      return 'grey';
    },
  },
  methods: {
    searchFundraisings(val) {
      this.fundraising_query = val;
      this.apiGet(`fundraisings/?fields=id,name&search=${val}&limit=10&offset=0`).then((response) => {
        this.fundraisings = response.data.results;
        this.optionsFundraisings = this.fundraisings.map((fundraising) => ({
          label: fundraising.name,
          id: fundraising.id,
        }));
      });
    },
    searchInvestors(val) {
      this.investor_query = val;
      this.apiGet(
        `investors/?fields=id,name,profile_image,investor_team&search=${val}&limit=10&offset=0`,
      ).then((response) => {
        this.investors = response.data.results;
        this.optionsInvestors = this.investors.map((investor) => ({
          label: investor.name,
          id: investor.id,
        }));
      });
    },
    selectFundraising(val) {
      this.fundraising_query = val.label;
      this.selectedFundraisingObj = this.fundraisings.find((fund) => fund.id == val.id);
      this.loading = true;
      this.apiGet(
        `/fundraisings/${this.selectedFundraisingObj.id}/?fields=startup_relationship,currency`,
      ).then(
        (response) => {
          this.selectedFundraisingObj.profile_image = response.data.startup_relationship.profile_image;
          this.selectedFundraisingObj.currency = response.data.currency;
          this.fundraisings = [];
          this.loading = false;
        },
        (response) => {
          this.loading = false;
        },
      );
    },
    selectInvestor(val) {
      this.investor_query = val.label;
      this.selectedInvestorObj = this.investors.find((investor) => investor.id == val.id);
      this.users = [];
      this.selectedInvestorObj.investor_team.forEach((rel) => {
        this.users.push({ value: rel.user.id, text: rel.user.full_name });
      });
    },
    selectUser(event) {
      const id = parseInt(event);
      const full_name = this.getNameFromSelect(id, this.users);
      this.user_query = full_name;
      this.selectedUserObj = { id, full_name };
    },
    toNextStep() {
      if (this.all_selected) {
        this.nextPage = true;
        this.getSelects();
      }
    },
    updateBody(value, field) {
      this.create_body[field] = value;
    },
    async createInvestment() {
      this.creating = true;
      this.create_body.investor = parseInt(this.selectedInvestorObj.id);
      this.create_body.fundraising = parseInt(this.selectedFundraisingObj.id);
      this.create_body.creator_user = parseInt(this.selectedUserObj.id);
      try {
        const response = await this.apiPost('/investments/', this.create_body);
        successAlert('The investment was correctly created.');
        this.$emit('created', response.data);
        this.hideModal();
      } catch (error) {
        errorAlert('Error! The investment could not be created.', error);
      }
      this.creating = false;
    },
    getSelects() {
      this.loading = true;
      this.apiGet('currencies/').then(
        (response) => {
          response.data.forEach((currency) => {
            this.currencies.push({ value: currency.id, text: currency.name });
          });
          this.loading = false;
        },
        () => {
          this.loading = false;
        },
      );
    },
    empty() {
      this.selectedFundraisingObj = {};
      this.selectedInvestorObj = {};
      this.selectedUserObj = {};
      this.nextPage = false;
      this.fundraising_query = '';
      this.investor_query = '';
      this.user_query = '';
      this.create_body = {
        committed_amount: '',
        status: 'validated', // Always
      };
    },
    hideModal() {
      this.empty();
      this.$emit('close');
    },
  },
  mounted() {
    document.body.style.overflow = 'hidden';
  },
  beforeUnmount() {
    document.body.style.overflow = '';
  },
};
</script>

<style lang="scss" scoped>

.purple {
  background-color: $purple-color !important;
}
.red {
  background-color: $red-color !important;
}

.selected-container {
  border: 1px solid $grey-color;
  border-radius: 5px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .selected-name {
    cursor: default;
    font: $main-font-bold;
    font-size: $slarge-font-size;
    color: $black-color;
    margin-left: 20px;
  }
  .selected-picture {
    cursor: default;
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
}

.label {
  font: $main-font-semibold;
  color: $black-color;
}
.validation-msg {
  color: $red-color;
}
</style>
