<template>
  <div class="tooltip-box">
    <span @mouseover="show = true" @mouseleave="show = false">
      <slot></slot>
    </span>
    <div
      :style="cssStyles"
      :class="[`tooltip tooltip-${color}`, {'hovered': show}]"
      v-if="visible"
    >
      <span
        :class="`message-tip ${color}`"
      >{{ text }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      required: true,
    },
    color: {
      type: String,
      default: 'white',
    },
    width: {
      type: String,
      required: false,
      default: '120px',
    },
    bottom: {
      type: String,
      required: false,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    left: {
      type: String,
      required: false,
    },
    triangleTop: {
      type: String,
      required: false,
    },
    triangleLeft: {
      type: String,
      required: false,
    },
  },
  computed: {
    cssStyles() {
      return {
        '--width': this.width || '120px',
        '--bottom': this.bottom || '100%',
        '--left': this.left || '50%',
        '--triangle-top': this.triangleTop || '100%',
        '--triangle-left': this.triangleLeft || '50%',
      };
    },
  },
  data() {
    return {
      show: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.tooltip-box {
  position: relative;
  display: inline-block;
}

.tooltip {
  text-align: center;
  padding: 5px 3px;
  border-radius: 8px;

  width: var(--width);
  bottom: var(--bottom);
  left: var(--left);
  margin-left: -60px;

  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s;

  position: absolute;
  z-index: 1000;

  box-shadow: 1px 1px 10px 1px grey !important;
}

.hovered {
  transition: opacity 0.5s;
  visibility: visible;
  opacity: 1;
}

.message-tip {
  @extend .text-md--regular;
}

.tooltip-white {
  background-color: $white-color;
  color: $black-color;
}

.tooltip-black {
  background-color: $black-color;
  color: $white-color;
}

.tooltip-blue {
  background-color: $blue-color;
  color: $white-color;
}
.tooltip-red {
  background-color: $red-color;
  color: $white-color;
}
.tooltip-purple {
  background-color: $purple-color;
  color: $white-color;
}
.tooltip-yellow {
  background-color: $yellow-color;
  color: $white-color;
}
.tooltip-green {
  background-color: $green-color;
  color: $white-color;
}

</style>
