<template>
  <div class="search-wrapper">
    <input
      type="search"
      v-model="query"
      @input="searchFor"
      @click="searchFor"
      :placeholder="placeholder"
      class="search-input form-control text-md--semibold"
      :class="{'selected-input': this.selected}"
    >
    <div class="dropdown-container" v-if="showDropdown">
      <div v-for="option in options" >
        <div class="d-flex align-items-center one-option"
          v-if="option.label?.length > 1 || option.text?.length > 1"
          @click="chooseOption(option)"
        >
          <div class="icon" v-if="showIcon">
            <img
              loading="lazy"
              :src="option.icon"
              v-if="option.icon"
              class="mx-auto img-fluid"
            />
            <img
              class="img-fluid mx-auto"
              v-else
              src="@/assets/images/no-avatar-natural.png"
            />
          </div>
          <div class="w-35 d-flex flex-column text-left" >
            <span class="label ">{{ option.label || option.text }}</span>
            <span class="email" v-if="option.email">{{ option.email }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ORTSearchDropdown',
  props: {
    options: {
      type: Array,
      required: true,
      default: [],
    },
    placeholder: {
      type: String,
      required: false,
    },
    showIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      query: null,
      selected: false,
    };
  },
  computed: {
    showDropdown() {
      return this.query && this.options.length && !this.selected;
    },
  },
  methods: {
    searchFor() {
      this.selected = false;
      this.$emit('input', this.query, this.selected);
    },
    chooseOption(value) {
      this.selected = true;
      this.query = value.label || value.text;
      this.$emit('chooseOption', value, this.selected);
    },
  },
};
</script>
<style lang="scss" scoped>
.icon {
  width: 64px;
  height: 64px;
  padding: 8px;
}
.search-wrapper {
  @extend .text-sm--semibold;
  position: relative;
}
.search-input {
  border: 1px solid $grey-500;
  border-radius: 6px;
  padding: 6px 12px;
  outline: medium none;
  width: 100%;
  height: 47px;

  &:focus {
    border: 1px solid $red-400;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px $red-500;
  }
}
.dropdown-container {
  width: 100%;
  overflow: auto;
  margin-top: 8px;
  text-align: left;
  padding: 12px 16px;
  border-radius: 8px;
  top: 100%;
  //right: 50%;
  z-index: 100000;
  box-shadow: 1px 1px 10px 1px grey;
  background-color: white;
  position: absolute;

  .one-option {
    padding: 5px 10px;

    .email {
      font: $main-font-regular;
    }
    .label {
     // color: #495057;
      @extend .text-md--semibold;
    }
  }
  .one-option:hover {
    cursor: pointer;
    background-color: $red-500;
    border-radius: 8px;
    color: $grey-100;
  }
}
</style>
